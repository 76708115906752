import { Network, ChainId } from '../types'

interface Props {
  chainId?: ChainId
  className?: string
}

export default function NetworkIcon({ chainId, className }: Props) {
  if (!chainId) return null

  switch (chainId) {
    case ChainId.GOERLI:
      return (
        <img
          alt="Polygon Logo"
          src="https://opensea.io/static/images/logos/ethereum.svg"
          width={15}
          height={15}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
          className={className}
        />
      )
    case ChainId.BASE_GOERLI:
      return (
        <img
          alt="Polygon Logo"
          src="https://mirror-media.imgix.net/publication-images/cgqxxPdUFBDjgKna_dDir.png?h=1200&w=1200"
          width={15}
          height={20}
          className="mr-2"
        />
      )
    case ChainId.POLYGON:
    case ChainId.POLYGON_ZKEVM:
    case ChainId.POLYGON_ZKEVM_TESTNET:
    case ChainId.POLYGON_MUMBAI:
      return (
        <img
          alt="Polygon Logo"
          src="https://opensea.io/static/images/logos/polygon.svg"
          width={15}
          height={15}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
          className={className}
        />
      )
    case ChainId.OPTIMISM_GOERLI:
    case ChainId.OPTIMISM:
      return (
        <img
          alt="Optimism Logo"
          src="https://opensea.io/static/images/logos/optimism.svg"
          width={15}
          height={15}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
          className={className}
        />
      )

    default:
      return null
  }
}
