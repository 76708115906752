import { ethers, BigNumber } from 'ethers'
import { useState, useEffect } from 'react'

import { useQuery } from '../../hooks'

export interface NFT {
  chain_id: number
  data: string
  to: string
  value: string
}

export default function Mint() {
  const query = useQuery()

  const [nft, setNft] = useState<NFT>()

  const token = query.get('token')

  async function fetchNFT() {
    const variables = {
      token,
    }

    const res = await fetch('https://payments-api-c560.onrender.com/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
              query ($token: String!) {
                decodeMintToken(token: $token) {
                    to
                    value
                    data
                    chain_id
                  }
                }
                `,
        variables,
      }),
    })

    const {
      data: { mintdecode },
    } = await res.json()

    setNft(mintdecode)
  }

  useEffect(() => {
    fetchNFT()
  }, [])

  async function mint() {
    if (!(window as any).ethereum) throw new Error('no window.ethereum')

    const provider = new ethers.providers.Web3Provider(
      (window as any).ethereum,
      'any',
    )

    if (!provider) {
      throw new Error('connect wallet webview - no provider')
    }
    const signer = provider.getSigner()
    if (!signer) throw new Error('no signer :(')

    if (!token) throw new Error('no token')

    const variables = {
      token,
    }

    const res = await fetch('https://payments-api-c560.onrender.com/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        query ($token: String!) {
            decodeMintToken(token: $token) {
              to
              value
              data
              chain_id
            }
          }
          `,
        variables,
      }),
    })

    const {
      data: { mintdecode },
    } = await res.json()

    console.log(JSON.stringify(mintdecode, null, 4))

    const tx = await signer.sendTransaction({
      to: mintdecode.to,
      data: mintdecode.data,
      value: mintdecode.value,
    })
    console.log(JSON.stringify(tx, null, 4))

    await tx.wait()
  }

  return (
    <div className="container mx-auto mt-10">
      <button onClick={mint}>Mint</button>

      <p> {nft?.to}</p>
    </div>
  )
}
