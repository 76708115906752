import { ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { FullscreenProvider } from './FullscreenProvider'
import { TaxProvider } from './TaxProvider'

interface Props {
  children: ReactNode
}

export default function ContextProvider({ children }: Props) {
  return (
    <FullscreenProvider>
      <BrowserRouter>
      <TaxProvider>{children}</TaxProvider>
      </BrowserRouter>
    </FullscreenProvider>
  )
}
