import { motion, Variants } from 'framer-motion'

interface Props {
  amount: string
}

const Char = ({ char }: { char: string }) => {
  if (char === '.') {
    return <div>{char}</div>
  }

  return (
    <motion.div
      className="select-none"
      initial={{ scale: 0.6 }}
      animate={{ scale: 1 }}
    >
      {char}
    </motion.div>
  )
}

export default function PriceTicker({ amount }: Props) {
  const digits = amount.split('')

  return (
    <div className="flex text-5xl font-light font-semibold justify-center items-center text-white dark:text-white tracking-wide">
      <div className="">$</div>
      {digits.map((d, i) => (
        <div
          className="flex slashed-zero font-semibold lining-nums select-none"
          key={i}
        >
          <Char char={d} />
        </div>
      ))}
    </div>
  )
}
