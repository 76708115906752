import classNames from 'classnames'
import { Routes, Route, Outlet, Link, useNavigate } from 'react-router-dom'
import { parseISO, format } from 'date-fns'
import { ClockIcon } from '@heroicons/react/20/solid'
import { groupDataByDay, chainName } from '../../utils'
import {
  PaymentStatusBadge,
  DesktopNavBar,
  NetworkIcon,
  BottomNavigation,
} from '../../components'

import { useInterval } from '../../hooks'
import { GroupedData, PaymentRequest, ChainId } from '../../types'

import { Status } from '.'

interface Props {
  status: Status
  data: GroupedData<PaymentRequest>
}

export default function TabelList({ status, data }: Props) {
  let navigate = useNavigate()

  return (
    <div className="mt-5 bg-black p-8 rounded-lg border border-neutral-950">
      <div className="relative overflow-x-auto mt-1">
        <table className="w-full table-auto w-full text-sm text-left text-neutral-500 border-collapse">
          <thead className="text-xs text-neutral-400">
            <tr>
              <th scope="col" className="text-base px-6 py-3">
                Payment Request ID
              </th>
              <th scope="col" className="text-base px-6 py-3">
                Currency
              </th>
              <th scope="col" className="text-base px-6 py-3">
                Amount
              </th>
              <th scope="col" className="text-base px-6 py-3">
                Network
              </th>
              <th scope="col" className="text-base px-6 py-3">
                Last updated
              </th>
              <th scope="col" className="text-base px-6 py-3">
                Status
              </th>
            </tr>
          </thead>

          {status === Status.Loading && (
            <tbody>
              <tr className="">
                <th scope="col" className="px-6 py-5 text-white text-base">
                  <div className="animate-pulse h-4 w-28 bg-neutral-700 rounded mb-2 mt-2"></div>
                </th>
              </tr>

              {Array.from({ length: 10 }, (_, index) => index + 1).map(
                (idx) => (
                  <tr
                    key={idx}
                    className="even:bg-neutral-950 border-0 hover:bg-neutral-800 cursor-pointer fadeIn"
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-neutral-200 whitespace-nowrap "
                    >
                      <div className="animate-pulse h-4 w-36 bg-neutral-700 rounded mb-2 mt-2"></div>
                    </th>
                    <td className="px-6 py-4 text-neutral-200">
                      <div className="animate-pulse h-4 w-16 bg-neutral-700 rounded mb-2 mt-2"></div>
                    </td>
                    <td className="px-6 py-4 text-neutral-200">
                      <div className="animate-pulse h-4 w-28 bg-neutral-700 rounded mb-2 mt-2"></div>
                    </td>
                    <td className="px-6 py-4 flex">
                      <div className="animate-pulse h-4 w-28 bg-neutral-700 rounded mb-2 mt-2"></div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="animate-pulse h-4 w-28 bg-neutral-700 rounded mb-2 mt-2"></div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="animate-pulse h-6 w-18 bg-neutral-700 rounded-full mb-2 mt-2"></div>
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          )}

          {status === Status.Ready && Object.values(data).length === 0 && (
            <tbody>
              <tr className="">
                <th scope="col" className="px-6 py-5 text-neutral-400">
                  <p>Looks like you don't have any history yet</p>
                </th>
              </tr>
            </tbody>
          )}

          {status === Status.Ready &&
            Object.entries(data).map(([day, evt]) => {
              return (
                <tbody>
                  <tr className="">
                    <th scope="col" className="px-6 py-5 text-white text-base">
                      {format(new Date(day), 'MMMM dd, yyyy')}
                    </th>
                  </tr>

                  {evt.map((p) => (
                    <tr
                      key={p.id}
                      onClick={() => navigate(`/activity/${p.id}`)}
                      // className="hover:bg-gray-50 cursor-pointer fadeIn"
                      // className={
                      // index % 2 === 0 ? 'even:bg-gray-100' : 'odd:bg-white'
                      // }
                      className="even:bg-neutral-950 border-0 hover:bg-neutral-800 cursor-pointer fadeIn"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-neutral-200 whitespace-nowrap "
                      >
                        {p.id}
                      </th>
                      <td className="px-6 py-4 text-neutral-200">
                        {p?.total?.currency}
                      </td>
                      <td className="px-6 py-4 text-neutral-200">
                        {p?.total?.value}
                      </td>
                      <td className="px-6 py-4 flex">
                        <NetworkIcon chainId={p.chain_id} />
                        <span className="ml-3">
                          {chainName(p.chain_id as ChainId)}
                        </span>
                      </td>
                      <td className="px-6 py-4">
                        {p?.updated_at &&
                          format(parseISO(p.updated_at), 'hh:mm:ss a')}
                      </td>
                      <td className="px-6 py-4">
                        <PaymentStatusBadge status={p.status} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              )
            })}
        </table>
      </div>
    </div>
  )
}
