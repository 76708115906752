import React, { useState } from 'react'
import {
  CheckoutSession,
  TipOption,
  DisplayItem,
  DisplayItemType,
} from '../../types'
import { formatCurrencyAmount } from '../../utils'

import { TIP_REACT_KEY } from '../../constants'

interface MoneyTipRadioProps {
  tips: TipOption[]
  checkoutSession?: CheckoutSession
  updateTipDisplayItem: (option: DisplayItem) => void
}

export default function TipSelect({
  tips,
  checkoutSession,
  updateTipDisplayItem,
}: MoneyTipRadioProps) {
  const [selectedTip, setSelectedTip] = useState<number | null>(tips[0]?.value)

  const total =
    checkoutSession?.display_items?.reduce(
      (acc, curr) => acc + Number(curr.value),
      0,
    ) ?? 0

  const handleTipChange = (option: TipOption) => {
    setSelectedTip(option.value)
    updateTipDisplayItem({
      key: TIP_REACT_KEY,
      label: 'Tip ' + option.label,
      type: DisplayItemType.GRATUITY,
      value: (total * option.value).toFixed(2),
    })
  }

  const updatedTotal = (
    Number(checkoutSession?.total?.value) +
    (selectedTip || 0) * total
  ).toFixed(2)

  return (
    <>
      <div className="md:max-w-2xl mb-5 md:mx-auto">
        {checkoutSession ? (
          checkoutSession?.display_items
            ?.concat([
              {
                key: TIP_REACT_KEY,
                label: 'Tip',
                type: DisplayItemType.GRATUITY,
                value: selectedTip ? (selectedTip * total)?.toFixed(2) : '0',
              },
            ])
            .map((item) => (
              <div
                key={item.key}
                className="flex justify-between text-neutral-400"
              >
                <p>{item.label}</p>
                <p>{Number(item.value).toFixed(2)} </p>
              </div>
            ))
        ) : (
          <>
            <div className="flex justify-between mb-2">
              <div className="animate-pulse h-4 w-8 bg-neutral-700 rounded "></div>
              <div className="animate-pulse  h-4 w-8 bg-neutral-700 rounded "></div>
            </div>
            <div className="flex justify-between mb-2">
              <div className="animate-pulse h-4 w-12 bg-neutral-700 rounded "></div>
              <div className="animate-pulse  h-4 w-8 bg-neutral-700 rounded "></div>
            </div>
            <div className="flex justify-between mb-2">
              <div className="animate-pulse h-4 w-8 bg-neutral-700 rounded "></div>
              <div className="animate-pulse  h-4 w-8 bg-neutral-700 rounded "></div>
            </div>
          </>
        )}

        {checkoutSession ? (
          <div className="flex text-white  semibold justify-between">
            <p>Total</p>
            <p>
              {updatedTotal} {checkoutSession?.total?.currency}
            </p>
          </div>
        ) : (
          <div className="flex justify-between">
            <div className="animate-pulse h-4 w-8 bg-neutral-700 rounded "></div>
            <div className="animate-pulse  h-4 w-14 bg-neutral-700 rounded"></div>
          </div>
        )}
      </div>

      <h3 className="mb-3 mt-8">Add a tip ?</h3>

      <div className="grid grid-cols-3 gap-4">
        {tips.map((tip) => {
          if (checkoutSession) {
            return (
              <div
                key={tip.key}
                className={`flex flex-col p-3 rounded-xl cursor-pointer ${
                  selectedTip === tip.value
                    ? 'bg-neutral-600 text-greeeen'
                    : 'bg-neutral-900 text-gray-100 hover:bg-neutral-900'
                }`}
                onClick={() => handleTipChange(tip)}
              >
                <input
                  type="radio"
                  className="hidden"
                  checked={selectedTip === tip.value}
                  onChange={() => handleTipChange(tip)}
                />
                <p className="font-bold">{tip.label}</p>
                <span className="text-neutral-300 text-sm">
                  {formatCurrencyAmount(total * tip.value)}
                </span>
              </div>
            )
          } else {
            return (
              <div className="animate-pulse  h-20 w-100 bg-neutral-800 rounded-xl"></div>
            )
          }
        })}
      </div>
      {checkoutSession ? (
        <div className="grid grid-cols-2 gap-4 mt-5">
          <div
            onClick={
              () => {}
              /* handleTipChange({
              label: 'x',
              value: 11111111,
            })*/
            }
            className={`flex flex-col items-center p-3 rounded-xl cursor-pointer ${
              selectedTip === 11111111
                ? 'bg-neutral-500 text-white'
                : 'bg-neutral-900 text-gray-100 hover:bg-neutral-900'
            }`}

            //  className={`flex flex-col items-center p-3 rounded-lg cursor-pointer transition-colors duration-300 bg-neutral-900 text-gray-100 hover:bg-neutral-200`}
            // onClick={() => handleTipChange(tip.value)}
          >
            <input
              type="radio"
              className="hidden"
              checked={selectedTip === 11111111}
            />
            <span>Custom </span>

            {/*<span className="ml-auto">${tip.value.toFixed(2)}</span>*/}
          </div>

          <div
            onClick={() =>
              handleTipChange({
                key: TIP_REACT_KEY,
                label: 'No Tip',
                value: 0,
              })
            }
            className={`flex flex-col items-center p-3 rounded-xl cursor-pointer ${
              selectedTip === 0
                ? 'bg-neutral-500 text-white'
                : 'bg-neutral-900 text-gray-100'
            }`}
            // className={`flex flex-col items-center p-3 rounded-lg cursor-pointer transition-colors duration-300 bg-neutral-900 text-gray-100 hover:bg-neutral-200`}
            // onClick={() => handleTipChange(tip.value)}
          >
            <input
              type="radio"
              className="hidden"
              checked={selectedTip === 0}
            />
            <span>No tip</span>

            {/*<span className="ml-auto">${tip.value.toFixed(2)}</span>*/}
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-4 mt-5">
          <div className="animate-pulse  h-12 w-100 bg-neutral-800 rounded-xl"></div>
          <div className="animate-pulse  h-12 w-100 bg-neutral-800 rounded-xl"></div>
        </div>
      )}

      <div className="grid grid-cols-1 gap-4 mt-5"></div>
    </>
  )
}
