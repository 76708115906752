import { CheckoutSession, Network } from '../../types'
import { useMemo } from 'react'
import { ethers, utils, Contract } from 'ethers'
import { formatNetworkName } from '../../utils'

interface Props {
  network: Network | null
  checkoutSession?: CheckoutSession
}

export default function BalanceWarning({ network, checkoutSession }: Props) {
  const contractAddress = useMemo(() => {
    return checkoutSession?.networks?.find((method) => method.chain === network)
      ?.tokens[0].address
  }, [checkoutSession, network])

  /*
  const nativeBalance = useMemo(() => {
    return checkoutSession?.wallet?.native_balance?.value
  }, [checkoutSession?.wallet?.native_balance?.value])

  const hasNativeBalance = useMemo(() => {
    return Number(nativeBalance) > 0.1 // ????
  }, [nativeBalance])
*/

  const symbol = useMemo(() => {
    return checkoutSession?.networks?.find((method) => method.chain === network)
      ?.tokens[0].symbol
  }, [checkoutSession, network])

  async function mintTestTokens() {
    if (!contractAddress) throw new Error('no contractAddress')

    const provider = new ethers.providers.Web3Provider(
      (window as any).ethereum,
      'any',
    )
    const signer = provider.getSigner()

    const accounts = await (window as any).ethereum.request({
      method: 'eth_requestAccounts',
    })
    if (!accounts[0]) throw new Error('no account')

    const contract = new Contract(
      contractAddress,
      [
        {
          name: 'mint',
          type: 'function',
          stateMutability: 'nonpayable',
          outputs: [],
          inputs: [
            {
              internalType: 'address',
              name: '_account',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: '_amount',
              type: 'uint256',
            },
          ],
        },
      ],
      signer,
    )

    const tx = await contract.mint(accounts[0], utils.parseUnits('1000', 6))

    await tx.wait()
  }

  if (!checkoutSession?.id) return null

  return (
    <div className="p-4 bg-neutral-900 rounded-lg">
      <p className="flex">
        ℹ️ You don't have enough {formatNetworkName(network ?? '')} {symbol}{' '}
        balance to complete the payment.
      </p>

      {network &&
        ![Network.POLYGON, Network.OPTIMISM, Network.ARBITRUM].includes(
          network,
        ) && (
          <p className="mt-5">
            You can mint some {symbol} test tokens{' '}
            <button
              className="underline font-semibold"
              onClick={mintTestTokens}
            >
              here
            </button>
          </p>
        )}
    </div>
  )
}
