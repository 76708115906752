export function fetchWithTimeout(
  url: string,
  options: RequestInit = {},
  timeout: number,
): Promise<Response> {
  return new Promise((resolve, reject) => {
    // Create a new AbortController instance
    const controller = new AbortController()
    const timeoutId = setTimeout(() => {
      // Abort the fetch request if it takes longer than the specified timeout
      controller.abort()
      reject(new Error('Request timed out'))
    }, timeout)

    fetch(url, {
      ...options,
      signal: controller.signal,
    })
      .then((response) => {
        clearTimeout(timeoutId) // Clear the timeout
        resolve(response)
      })
      .catch((error) => {
        clearTimeout(timeoutId) // Clear the timeout
        reject(error)
      })
  })
}
