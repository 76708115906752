import React, { FC, ReactNode, useEffect, useState } from 'react'
import { createContext } from 'react'

import { isFullscreen, toggleFullscreen } from '../utils/fullscreen'

export interface FullscreenContextState {
  fullscreen: boolean
  toggleFullscreen(): void
}

export const FullscreenContext = createContext<FullscreenContextState>(
  {} as FullscreenContextState,
)

export interface Props {
  children: ReactNode
}

export const FullscreenProvider: FC<Props> = ({ children }) => {
  const [fullscreen, setFullscreen] = useState(isFullscreen())

  useEffect(() => setFullscreen(isFullscreen()), [])

  useEffect(() => {
    const listener = () => setFullscreen(isFullscreen())

    document.addEventListener('fullscreenchange', listener)
    document.addEventListener('webkitfullscreenchange', listener)

    return () => {
      document.removeEventListener('fullscreenchange', listener)
      document.removeEventListener('webkitfullscreenchange', listener)
    }
  }, [])

  useEffect(() => {
    if (fullscreen) {
      document.documentElement.classList.add('fullscreen')
      return () => document.documentElement.classList.remove('fullscreen')
    }
  }, [fullscreen])

  return (
    <FullscreenContext.Provider value={{ fullscreen, toggleFullscreen }}>
      {children}
    </FullscreenContext.Provider>
  )
}
