import classNames from 'classnames'

interface Props {
  labelText: string
  type: string
}

export const inputStyles = (showValidation: boolean) =>
  classNames(
    'form-control',
    'block',
    'w-full',
    'px-4',
    'py-2.5',
    'text-lg',
    'font-normal',
    'text-gray-700',
    'dark:text-white',
    'bg-neutral-950',
    'dark:bg-neutral-800',
    'bg-clip-padding',
    // 'border',
    // 'border-solid',
    'rounded-xl',
    'transition',
    'ease-in-out',
    'm-0',
    'focus:bg-neutral-700',
    'focus:border-neutral-400',
    'focus:outline-none',
    //'placeholder:italic',
    'placeholder:text-neutral-500',
    showValidation ? 'border-red-400' : 'border-neutral-700',
  )

export default function Input({ type, labelText }: Props) {
  return (
    <div className="flex flex-col justify-center mb-4">
      <label
        htmlFor="exampleTel0"
        className="form-label inline-block mb-2 text-gray-700 dark:text-gray-300"
      >
        {labelText}
      </label>
      <input
        type={type}
        className={classNames(
          'form-control',
          'block',
          'w-full',
          'px-3',
          'py-2.5',
          'text-lg',
          'font-normal',
          'text-gray-700',
          'dark:text-white',
          // 'dark:text-neutral-500',
          'dark:bg-neutral-900',
          'bg-clip-padding',
          'border',
          'border-solid',
          'dark:border-none',
          'border-gray-300',
          'rounded-lg',
          'transition',
          'ease-in-out',
          'm-0',
          'focus:bg-neutral-700',
          'focus:border-blue-600',
          'focus:outline-none',
        )}
        id="exampleTel0"
        placeholder="(xxx) xxx-xxxx"
      />
    </div>
  )
}
