import { DotAnimation } from '..'
import { CheckoutSession } from '../../types'

interface Props {
  checkoutSession: CheckoutSession
}

export default function ApprovalPending({ checkoutSession }: Props) {
  return (
    <div className="max-w-sm min-h-screen mx-auto flex flex-col justify-center p-10">
      <div className="max-w-sm flex flex-col justify-center text-center">
        <div className="flex justify-center mb-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-14 h-14 text-neutral-800 mb-7"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
            />
          </svg>
        </div>

        <p className="font-semibold text-lg mb-7">Approving</p>

        <DotAnimation />
        <p>{/*formatAddress(payment?.transactionHash ?? '') FIXMR*/}</p>
      </div>
    </div>
  )
}
