import { useEffect } from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'

import { DotAnimation } from '../../components'
import { CheckoutSession } from '../../types'

import { API_KEY } from '../../constants'

interface Props {
  shouldCapture: boolean
  checkoutSession: CheckoutSession
}

export default function Approved({ checkoutSession, shouldCapture }: Props) {
  async function capturePayment() {
    try {
      const res = await fetch(
        `https://payments-api-c560.onrender.com/api/v1/payment_requests/${checkoutSession?.id}/capture`,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'X-API-KEY': API_KEY,
          },
        },
      )

      if (!res.ok) throw new Error('error capturing payment')
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (shouldCapture) {
      capturePayment()
    }
  }, [])

  return (
    <div className="flex flex-col justify-center items-center min-h-screen">
      <div className="flex flex-col justify-center items-center">
        <CheckIcon className="h-16 w-16 mb-4 text-neutral-800" />

        <p className="font-semibold text-lg mb-7">Finalizing</p>
        <DotAnimation />
      </div>
    </div>
  )
}
