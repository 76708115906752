import {
  Network,
  CheckoutSession,
  networkToChainId,
  ChainId,
} from '../../types'
import { utils } from 'ethers'
import classNames from 'classnames'

import { formatAddress } from '../../utils'

function ChainLogo({ chainId }: { chainId?: ChainId }) {
  switch (chainId) {
    case networkToChainId[Network.POLYGON]:
    case networkToChainId[Network.POLYGON_MUMBAI]:
      return (
        <img
          alt="Polygon Logo"
          src="https://opensea.io/static/images/logos/polygon.svg"
          width={15}
          height={15}
          style={{ maxWidth: '100%', height: 'auto' }}
          className="mr-2"
        />
      )
    case networkToChainId[Network.GOERLI]:
      return (
        <img
          alt="Ethereum Logo"
          src="https://opensea.io/static/images/logos/ethereum.svg"
          width={15}
          height={15}
          style={{ maxWidth: '100%', height: 'auto' }}
          className="mr-2"
        />
      )
    case networkToChainId[Network.BASE_GOERLI]:
      return (
        <img
          alt="Polygon Logo"
          src="https://mirror-media.imgix.net/publication-images/cgqxxPdUFBDjgKna_dDir.png?h=1200&w=1200"
          width={15}
          height={15}
          style={{ maxWidth: '100%', height: 'auto' }}
          className="mr-2"
        />
      )
    default:
      return null
  }
}

interface Props {
  checkoutSession?: CheckoutSession
  wallet: string | null
  chainId?: ChainId
  account: string
  hasWalletInfoLoaded: boolean
}

export default function NavBar({
  checkoutSession,
  wallet,
  chainId,
  account,
  hasWalletInfoLoaded,
}: Props) {
  const USDC = checkoutSession?.wallet?.tokens.find(
    (method) => method.symbol === 'USDC',
  )

  return (
    <nav className="flex flex-col">
      <div className="flex justify-end">
        <ChainLogo chainId={chainId} />

        <p className="ml-3">{formatAddress(account)}</p>
      </div>

      {hasWalletInfoLoaded ? (
        <div className="flex flex-col items-end mt-2">
          <p
            className={classNames('text-sm', 'text-right', 'text-neutral-300')}
          >
            {`${Number(
              utils.formatUnits(
                String(checkoutSession?.wallet?.native_balance?.value),
                checkoutSession?.wallet?.native_balance?.decimals,
              ),
            ).toFixed(6)} ${checkoutSession?.wallet?.native_balance?.symbol}`}
          </p>

          <p
            className={classNames('text-sm', 'text-right', 'text-neutral-300')}
          >
            {`${Number(
              utils.formatUnits(String(USDC?.balance), USDC?.decimals),
            ).toFixed(2)} ${USDC?.symbol}`}
          </p>
        </div>
      ) : (
        <div className="flex flex-col items-end mt-2">
          <div className="animate-pulse h-4 w-32 bg-neutral-700 rounded mb-2 mt-2"></div>
          <div className="animate-pulse h-4 w-16 bg-neutral-700 rounded"></div>
        </div>
      )}
    </nav>
  )
}
