import { PaymentStatus } from '../types'
import classNames from 'classnames'

interface Props {
  status?: PaymentStatus
}

export default function PaymentStatusBadge({ status }: Props) {
  let className = ''
  let textLabel = ''

  if (!status) return null

  switch (status) {
    case PaymentStatus.CREATED:
      className = 'bg-green-500 bg-opacity-20 text-green-500 text-opacity-60'
      textLabel = 'Captured'
      break
    case PaymentStatus.PAYER_ACTION_REQUIRED:
      className = 'text-pink-600 bg-pink-200 blinker'
      textLabel = 'Payer Action Required'
      break
    case PaymentStatus.APPROVAL_PENDING:
      className =
        'bg-blue-500 bg-opacity-20 text-blue-500 text-opacity-60 blinker'
      textLabel = 'Approving'
      break
    case PaymentStatus.APPROVED:
      className = 'bg-blue-500 bg-opacity-20 text-blue-500 text-opacity-60'
      textLabel = 'Approved'
      break

    case PaymentStatus.CAPTURE_PENDING:
      className =
        'bg-green-500 bg-opacity-20 text-green-500 text-opacity-60 blinker'
      textLabel = 'Capturing'
      break

    case PaymentStatus.CAPTURED:
      className = 'bg-green-500 bg-opacity-20 text-green-500 text-opacity-60'
      textLabel = 'Captured'
      break

    case PaymentStatus.SETTLEMENT_PENDING:
      className =
        'text-neutral-100 bg-neutral-500 text-opacity-60 bg-opacity-20 blinker'
      textLabel = 'Settled'
      break

    case PaymentStatus.SETTLED:
      className =
        'text-neutral-100 bg-neutral-500 text-opacity-60 bg-opacity-20'
      textLabel = 'Settled'
      break
  }

  if (className === '' || textLabel === '') return null //throw new Error('invlis status')

  return (
    <span
      className={classNames(
        'fadeIn text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full uppercase last:mr-0 mr-1',
        className,
      )}
    >
      {textLabel}
    </span>
  )
}
