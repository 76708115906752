import classNames from 'classnames'

import { inputStyles } from './Input'

interface Props {
  value: string
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  showValidation: boolean
  onFocus: () => void
}

export default function EmailInput({
  value,
  onChange,
  showValidation,
  onFocus,
}: Props) {
  return (
    <div className="flex flex-col justify-center mb-4">
      <label
        htmlFor="exampleTel0"
        className="block text-sm font-medium leading-6 text-white dark:text-white mb-2"
      >
        Email
      </label>
      <input
        value={value}
        type="email"
        className={inputStyles(showValidation)}
        id="exampleTel0"
        placeholder="email@email.com"
        onChange={onChange}
        onFocus={onFocus}
      />
      {showValidation && (
        <span className="inline-flex text-sm text-red-400 mt-2">
          Invalid email address
        </span>
      )}
    </div>
  )
}
