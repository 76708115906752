import classNames from 'classnames'
import { Routes, Route, Outlet, Link, useNavigate } from 'react-router-dom'
import { parseISO, format } from 'date-fns'
import { ClockIcon } from '@heroicons/react/20/solid'
import { groupDataByDay, chainName } from '../../utils'
import {
  PaymentStatusBadge,
  DesktopNavBar,
  NetworkIcon,
  BottomNavigation,
} from '../../components'

import { useInterval } from '../../hooks'
import { GroupedData, PaymentRequest, ChainId } from '../../types'

import { Status } from '.'

interface Props {
  status: Status
  data: GroupedData<PaymentRequest>
}

export default function MobileList({ status, data }: Props) {
  let navigate = useNavigate()

  return (
    <div className="p-0">
      {status === Status.Loading && 'LOADING....'}

      {Object.values(data).length === 0 && (
        <tbody>
          <tr className="">
            <th scope="col" className="px-6 py-5 text-neutral-400">
              <p>Looks like you don't have any history yet</p>
            </th>
          </tr>
        </tbody>
      )}

      {Object.entries(data).map(([day, evt]) => {
        return (
          <div>
            <h3 className="mb-5"> {format(new Date(day), 'MMMM dd, yyyy')}</h3>

            <ul>
              {evt.map((p) => (
                <li
                  key={p.id}
                  onClick={() => navigate(`/activity/${p.id}`)}
                  // className="hover:bg-gray-50 cursor-pointer fadeIn"
                  // className={
                  // index % 2 === 0 ? 'even:bg-gray-100' : 'odd:bg-white'
                  // }
                  //  className="even:bg-neutral-950 border-0 hover:bg-neutral-800 cursor-pointer fadeIn"
                  className="mb-5"
                >
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <p className="mb-2 font-semibold">
                        {' '}
                        {p?.total?.value} {p?.total?.currency}
                      </p>
                      <div className="flex">
                        <NetworkIcon chainId={p.chain_id} />
                        <p className="ml-3 text-neutral-400">
                          {chainName(p.chain_id as ChainId)}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="mb-2">
                        {' '}
                        <PaymentStatusBadge status={p.status} />
                      </div>
                      <p className="text-neutral-500 text-sm">
                        {' '}
                        {p?.updated_at &&
                          format(parseISO(p.updated_at), 'hh:mm:ss a')}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )
      })}
    </div>
  )
}
