import { useState, useEffect } from 'react'
import { ReciptInfo } from '../../types'

interface Props {
  recipt?: ReciptInfo
}

export interface Metadata {
  description: string
  external_url: string
  image: string
  name: string
  attributes: Attribute[]
}

export interface Attribute {
  trait_type: string
  value: number | string
  display_type?: string
}

export default function ReciptCard({ recipt }: Props) {
  const [metaData, setMetaData] = useState<Metadata>()

  useEffect(() => {
    async function fetchMetadata() {
      if (!recipt?.uri) throw new Error('no uri :(')

      const res = await fetch(recipt?.uri)
      if (!res.ok) throw new Error('err fetching metadata :(')
      const data = await res.json()
      setMetaData(data)
    }

    if (recipt?.uri) {
      fetchMetadata()
    }
  }, [recipt?.uri])

  return (
    <div className="flex flex-col relative mb-12">
      <div className="flex w-full flex-row justify-center">
        <h1 className="text-white font-semibold text-lg mb-4">
          {metaData?.name}
        </h1>
      </div>

      <div className="flex w-full flex-col justify-center items-center">
        <img
          alt="Voucher"
          src={metaData?.image}
          className="mr-2 rounded-md bg-white border border-slate-200 p-2 mb-5 shadow max-w-xs mb-5"
        />
        <p className="text-white mb-8">{metaData?.description}</p>

        <p className="text-white mb-2">
          Your recipt should show up in your wallet shortly
        </p>
        <p className="text-neutral-400 mb-2">
          Please note these may not show in wallets for testnets
        </p>
      </div>
    </div>
  )
}
