export function encodeQueryData(data: {
  [key: string]: string | undefined | null
}): string {
  const ret: string[] = []

  for (const d in data) {
    if (data[d]) {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]!))
    }
  }
  return !!ret.length ? `?${ret.join('&')}` : ''
}

export function objectToQueryString(object: { [key: string]: any }): string {
  return Object.entries(object)
    .map(([key, value]) => {
      if (typeof value === 'boolean') {
        value = value ? 1 : 0 // convert boolean to number
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    })
    .join('&')
}

export function testDeepLink(url: string) {
  var newWindow = window.open(url, '_blank')

  if (newWindow) {
    // Deep link can be opened
    newWindow.close()
    console.log('Deep link can be opened successfully.')
  } else {
    // Deep link cannot be opened
    console.log('Failed to open deep link.')
  }
}
