import { ethers } from 'ethers'
import { useState, useEffect } from 'react'
import { Wallet } from '../../types'
import { formatAddress } from '../../utils'
import { ButtonPill } from '../../components'

import { API_KEY } from '../../constants'

export default function Sign() {
  const [errorMessage, setErrorMessage] = useState('')
  const [walletAddress, setWalletAddress] = useState('')
  const [nonce, setNonce] = useState('')
  const [wallet, setWallet] = useState<Wallet>()

  async function signMessage() {
    if (!(window as any).ethereum) throw new Error('no window.ethereum')

    const provider = new ethers.providers.Web3Provider(
      (window as any).ethereum,
      'any',
    )

    if (!provider) throw new Error('connect wallet webview - no provider')

    const signer = provider.getSigner()
    if (!signer) throw new Error('no signer :(')

    const accounts = await (window as any).ethereum.request({
      method: 'eth_requestAccounts',
    })
    if (!accounts[0]) throw new Error('connect wallet webview - no account')

    const signature = await signer.signMessage(nonce)

    const res = await fetch(
      `https://payments-api-c560.onrender.com/api/v1/wallet_user/${accounts[0]}/verify`,
      {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'X-API-KEY': API_KEY,
        },
        body: JSON.stringify({
          signature,
        }),
      },
    )
    const { token } = await res.json()

    window.location.href = `terminalapp://register?key=${token}`
  }

  useEffect(() => {
    findOrCreate()

    if ((window as any).ethereum?.isBraveWallet) {
      setWallet(Wallet.Brave)
    } else if ((window as any).ethereum?.isMetaMask) {
      setWallet(Wallet.MetaMask)
      return
    } else if ((window as any).ethereum?.isMetaMask) {
      setWallet(Wallet.MetaMask)
      return
    }
  }, [])

  async function findOrCreate() {
    try {
      if (!(window as any).ethereum) throw new Error('no window.ethereum')

      const provider = new ethers.providers.Web3Provider(
        (window as any).ethereum,
        'any',
      )

      if (!provider) {
        throw new Error('connect wallet webview - no provider')
      }
      const signer = provider.getSigner()
      if (!signer) throw new Error('no signer :(')

      const accounts = await (window as any).ethereum.request({
        method: 'eth_requestAccounts',
      })
      if (!accounts[0]) throw new Error('connect wallet webview - no account')
      setWalletAddress(accounts[0])

      const res = await fetch(
        `https://payments-api-c560.onrender.com/api/v1/wallet_user`,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
          },
          body: JSON.stringify({
            address_eth: accounts[0],
            network: 'eth',
          }),
        },
      )
      const { nonce } = await res.json()
      setNonce(nonce)
    } catch (err) {
      setErrorMessage((err as any).message)
    }
  }

  return (
    <div className="fixed left-0 top-0 right-0 bottom-0 h-screen flex flex-col justify-center items-center p-5">
      {/* <h3>Sign msg {wallet}</h3>*/}
      <p>{errorMessage}</p>
      <p className="mb-20">{formatAddress(walletAddress)}</p>
      {
        <ButtonPill fullWidth={true} onClick={signMessage}>
          Sign in
        </ButtonPill>
      }
    </div>
  )
}
