import { CheckoutSession, Source } from '../../types'
import {
  CheckIcon,
  XMarkIcon,
  ViewfinderCircleIcon,
} from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'

interface Props {
  checkoutSession: CheckoutSession
  source?: Source
}

export default function Completed({ source, checkoutSession }: Props) {
  const navigate = useNavigate()

  return (
    <div className="h-screen flex justify-center items-center">
      {source === Source.TERMINAL && (
        <button
          onClick={() => {
            navigate(`/`)
          }}
          className="fixed left-5 top-5"
        >
          <XMarkIcon className="h-8 w-8 text-gray-300" />
        </button>
      )}

      <div className="flex flex-col items-center">
        <h3 className="font-semibold text-lg mb-7">Received</h3>
        <CheckIcon className="h-16 w-16 mb-7 text-green-400" />
        <h3>Thank you</h3>
      </div>
    </div>
  )
}
