import './DotAnimation.css'

interface Props {
  text?: string
}

export default function DotAnimation({ text = 'Please wait...' }: Props) {
  return (
    <div
      className="flex items-center justify-center"
      style={
        {
          //background: 'rgba(0, 0, 0, 0.3)',
        }
      }
    >
      <div className=" py-2 px-5 rounded-lg flex items-center flex-col">
        <div className="loader-dots block relative w-20 h-5 mt-2">
          <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-600"></div>
          <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-600"></div>
          <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-600"></div>
          <div className="absolute top-0 mt-1 w-3 h-3 rounded-full bg-green-600"></div>
        </div>
        <div className="text-gray-500 text-xs font-light mt-2 text-center">
          {text}
        </div>
      </div>
    </div>
  )
}

/*
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #9880ff;
  }
  50%, 100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}
*/
