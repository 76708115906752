import { ChainId } from '../types'

export function chainName(chainId?: ChainId) {
  if (!chainId) return ''

  switch (chainId) {
    case ChainId.GOERLI:
      return 'Goerli'
    case ChainId.BASE_GOERLI:
      return 'Base goerli'
    case ChainId.POLYGON:
      return 'Polygon'
    case ChainId.POLYGON_ZKEVM:
      return 'Polygon ZKEvm'
    case ChainId.POLYGON_ZKEVM_TESTNET:
      return 'Polygon ZKEvm Testnet'
    case ChainId.POLYGON_MUMBAI:
      return 'Polygon Mumbai'
    case ChainId.OPTIMISM:
      return 'Optimism'
    case ChainId.OPTIMISM_GOERLI:
      return 'Optimism goerli'

    default:
      return 'Unknown Chain'
  }
}

export enum ExplorerType {
  Hash = 'tx',
  Address = 'address',
  Batch = 'batch',
  Token = 'token',
}

export function makeExplorerLink({
  chainId,
  hash,
  type,
  tokenId,
}: {
  chainId?: ChainId
  hash?: string
  type: ExplorerType
  tokenId?: number
}) {
  if (!chainId || !hash) return ''

  let url = ''

  switch (chainId) {
    case ChainId.GOERLI:
      url = `https://goerli.etherscan.io/${type}/${hash}`
      if (tokenId) {
        url += `?a=${tokenId}`
      }
      return url

    case ChainId.BASE_GOERLI:
      url = `https://goerli.basescan.org/${type}/${hash}`
      if (tokenId) {
        url += `?a=${tokenId}`
      }
      return url

    case ChainId.POLYGON:
      url = `https://polygonscan.com/${type}/${hash}`
      if (tokenId) {
        url += `?a=${tokenId}`
      }
      return url

    case ChainId.POLYGON_ZKEVM:
      url = `https://zkevm.polygonscan.com/${type}/${hash}`
      if (tokenId) {
        url += `?a=${tokenId}`
      }
      return url

    case ChainId.POLYGON_ZKEVM_TESTNET:
      url = `https://testnet-zkevm.polygonscan.com/${type}/${hash}`
      if (tokenId) {
        url += `?a=${tokenId}`
      }
      return url

    case ChainId.POLYGON_MUMBAI:
      url = `https://mumbai.polygonscan.com/${type}/${hash}`
      if (tokenId) {
        url += `?a=${tokenId}`
      }
      return url

    case ChainId.OPTIMISM:
      url = `https://mumbai.polygonscan.com/${type}/${hash}`
      if (tokenId) {
        url += `?a=${tokenId}y`
      }

      return url

    case ChainId.OPTIMISM_GOERLI:
      url = `https://goerli-optimism.etherscan.io/${type}/${hash}`
      if (tokenId) {
        url += `?a=${tokenId}`
      }
      return url

    default:
      return 'Unknown Chain'
  }
}
