import classNames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { CheckoutSession } from '../types'
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import {
  CheckIcon,
  ChevronUpDownIcon,
  ChevronDownIcon,
} from '@heroicons/react/20/solid'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css'
import 'swiper/css/navigation'

import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper'

import { Network } from '../types'

const buttonClassNames = ({
  network,
  active,
}: {
  network: Network
  active: boolean
}) =>
  classNames(
    'inline-flex',
    'items-center',
    'justify-between',
    'w-full',
    'w-full',
    'px-4',
    'text-gray-600',
    'bg-neutral-100',
    'dark:text-neutral-300',
    'dark:bg-neutral-900',
    'rounded-lg',
    'cursor-pointer',
    // 'dark:bg-neutral-200',
    active ? 'dark:bg-neutral-700' : 'dark:bg-neutral-900',
    active ? 'dark:text-white' : 'dark:text-neutral-300',
    'outline-pink-500',
    active ? 'outline-1' : 'outline-0',
    'shadow',
    'text-lg',
    'font-semibold',
    [
      Network.OPTIMISM_GOERLI,
      Network.BASE_GOERLI,
      Network.POLYGON_ZKEVM_TESTNET,
    ].includes(network)
      ? 'py-4'
      : 'py-4',
  )
/*
 * Descktop swiper settings
 */
/*
  slidesPerView={2.5}
  spaceBetween={20}
  cssMode={true}
  navigation={true}
  pagination={false}
  mousewheel={true}
  freeMode={true}
  keyboard={true}
  modules={[Navigation, Pagination, Mousewheel, Keyboard]}
  className="mySwiper"*/

interface IconProps {
  network: Network
}

function getLogoSrc(network: Network) {
  let src = ''
  let width = 20
  let height = 20

  switch (network) {
    case Network.POLYGON:
    case Network.POLYGON_MUMBAI:
      src = 'https://opensea.io/static/images/logos/polygon.svg'
      break
    case Network.GOERLI:
      src = 'https://opensea.io/static/images/logos/ethereum.svg'
      break
    case Network.BASE_GOERLI:
      width = 25
      height = 20
      src =
        'https://mirror-media.imgix.net/publication-images/cgqxxPdUFBDjgKna_dDir.png?h=1200&w=1200'
      break
    case Network.ARBITRUM:
      src = 'https://opensea.io/static/images/logos/arbitrum.svg'
      break
    case Network.OPTIMISM:
    case Network.OPTIMISM_GOERLI:
      src = 'https://opensea.io/static/images/logos/optimism.svg'
      break
    case Network.POLYGON_ZKEVM:
    case Network.POLYGON_ZKEVM_TESTNET:
      src = 'https://opensea.io/static/images/logos/polygon.svg'
      break

    case Network.SOLANA:
      src = 'https://opensea.io/static/images/logos/solana.svg'
      break

    default:
      alert(network)
      throw new Error('unknown chain')
  }

  return src
}

function formatName(network: string) {
  const words = network.split('-').join(' ').split(' ') // Split the sentence into words

  const capitalizedWords = words.map((word) => {
    const firstLetter = word.charAt(0).toUpperCase() // Get the first letter of the word and capitalize it
    const restOfWord = word.slice(1) // Get the rest of the word (excluding the first letter)
    return firstLetter + restOfWord // Combine the capitalized first letter and the rest of the word
  })

  return capitalizedWords.join(' ') // Join the words back together into a sentence
}

interface Props {
  network: Network
  setNetwork: (network: Network) => void
  checkoutSession?: CheckoutSession
}

export default function NetworkSelect({
  network,
  setNetwork,
  checkoutSession,
}: Props) {
  const [selected, setSelected] = useState(network)

  function handleChange(network: Network) {
    setSelected(network)
    setNetwork(network)
  }

  return (
    <Listbox value={selected} onChange={handleChange}>
      {({ open }) => (
        <>
          {/*<Listbox.Label className="block text-sm font-medium leading-6 text-white dark:text-white">
            Network
      </Listbox.Label>*/}
          <div className="relative mt-2">
            <Listbox.Button
              className={classNames(
                'relative',
                'w-full',
                'cursor-default',
                'rounded-xl',
                //'bg-white',
                'dark:bg-neutral-900',
                'border-0',
                'py-4',
                'pl-3',
                'pr-10',
                'text-left',
                'text-white',
                'dark:text-white',
                'shadow-sm',
                'ring-1',
                // 'ring-inset',
                'ring-neutral-900',
                // 'focus:outline-none',
                // 'focus:ring-2',
                // 'focus:ring-indigo-500',
                'sm:text-sm',
                'sm:leading-6',
              )}
            >
              <span className="flex items-center">
                <img
                  src={getLogoSrc(selected)}
                  alt=""
                  className="h-6 w-6 flex-shrink-0 rounded-full"
                />
                <span className="font-semibold ml-5 block truncate">
                  {formatName(selected)}
                </span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-3">
                <ChevronDownIcon
                  className="h-7 w-7 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={classNames(
                  'absolute',
                  'z-10',
                  'mt-1',
                  'max-h-60',
                  'w-full',
                  'overflow-auto',
                  'rounded-md',
                  //  'bg-white',
                  'dark:bg-neutral-900',
                  'py-1',
                  'text-base',
                  'shadow-2xl',
                  'drop-shadow-2xl',
                  'ring-1',
                  'ring-black',
                  'ring-opacity-5',
                  'focus:outline-none',
                  'sm:text-sm',
                )}
              >
                {checkoutSession?.networks?.map((method) => (
                  <Listbox.Option
                    key={method.chain_id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-neutral-700 text-white' : 'text-gray-100',
                        'relative cursor-default select-none py-4 pl-3 pr-9',
                      )
                    }
                    value={method.chain}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <img
                            src={getLogoSrc(method.chain as Network)}
                            alt=""
                            className="h-5 w-5 flex-shrink-0 rounded-full"
                          />
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'ml-3 block truncate',
                            )}
                          >
                            {formatName(method.chain)}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-gray-900' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <CheckIcon
                              className="text-white h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
