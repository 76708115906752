import { useEffect, useState } from 'react'

interface Props {
  delay: number
  children: React.ReactNode
}

export default function FadeInDelayed({ delay, children }: Props) {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setShow(true)
      }, delay)
    }
  }, [])
  return <div className="fade-in">{show ? children : null}</div>
}
