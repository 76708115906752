//
import {
  CalendarDaysIcon,
  Squares2X2Icon,
  CalculatorIcon,
} from '@heroicons/react/20/solid'
import { NavLink } from 'react-router-dom'
import { TbActivityHeartbeat } from 'react-icons/tb'

import { IoIosKeypad } from 'react-icons/io'

const styles = {
  tabButton: 'group inline-flex flex-col items-center justify-center px-5',
  tabLabel: 'text-xs text-neutral-600 ', // group-hover:text-white dark:group-hover:text-white
  icon:
    'mb-1 h-5 w-5 text-neutral-600 group-hover:text-white dark:text-neutral-600 dark:group-hover:text-white',
}

export default function BottomNavigation() {
  return (
    <div className="fixed md:hidden bottom-0 left-0 h-16 w-full bg-black z-1">
      <div className="mx-auto grid h-full max-w-lg grid-cols-3 font-medium">
        <NavLink
          to="/"
          className={({ isActive, isPending }) =>
            isActive ? `${styles.tabButton} text-white` : styles.tabButton
          }
        >
          <IoIosKeypad />
          {/*<CalculatorIcon className={styles.icon} />*/}
          {/* <span className={styles.tabLabel}>Terminal</span>*/}
        </NavLink>

        <NavLink
          to="/activity"
          className={({ isActive, isPending }) =>
            isActive ? `${styles.tabButton} text-greeeen` : styles.tabButton
          }
        >
          <TbActivityHeartbeat className={styles.icon} />
          {/* <CalendarDaysIcon className={styles.icon} />*/}
          {/*<span className={styles.tabLabel}>Activity</span>*/}
        </NavLink>

        <NavLink
          to="/settings"
          className={({ isActive, isPending }) =>
            isActive ? `${styles.tabButton} text-white` : styles.tabButton
          }
        >
          <svg
            className={styles.icon}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              clipRule="evenodd"
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
            ></path>
          </svg>
          {/* <span className={styles.tabLabel}>Settings</span>*/}
        </NavLink>
      </div>
    </div>
  )
}
