import { useEffect, useState, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion, Variants } from 'framer-motion'
import classNames from 'classnames'
import { EllipsisVerticalIcon, XMarkIcon } from '@heroicons/react/20/solid'

import {
  ShakeAnimation,
  SelectCurrencyFiat,
  DesktopNavBar,
  BottomNavigation,
} from '../../components'

import { useSalesTax, useDevice } from '../../hooks'
import { API_KEY } from '../../constants'

import MenueMobile from './MenuMobile'
import PriceTicker from './PriceTicker'
import Keypad from './keypad'

interface SheetViewProps {
  isOpen: boolean
  onClose: () => void
}

const SheetView = ({ isOpen, onClose }: SheetViewProps) => {
  const [isClosed, setIsClosed] = useState(true)

  const { phone } = useDevice()

  function handleOnClose() {
    setIsClosed(false)
    onClose()

    setTimeout(() => {
      setIsClosed(true)
    }, 500)
  }

  return (
    <div
      className={`fixed top-0 left-0 bottom-0  right-0 flex items-end justify-center p-0  ${
        isOpen ? 'z-50' : isClosed ? '-z-10' : 'z-50'
      }`}
    >
      <div
        className={`h-screen bg-black rounded-t-lg shadow-xl  transform transition-transform duration-500 w-full ${
          isOpen
            ? phone
              ? 'translate-y-24'
              : 'translate-y-0'
            : 'translate-y-full'
        }`}
      >
        {/* Sheet content */}
        <div className="p-4 flex justify-center pt-20">
          <div className="w-64 h-64 bg-neutral-700"></div>
        </div>

        {/* Close button */}
        <button onClick={handleOnClose} className="p-3 absolute right-2 top-2">
          <XMarkIcon className="w-8 h-8" />
        </button>
      </div>
    </div>
  )
}

enum Status {
  Ready,
  Loading,
}

export default function Home() {
  const navigate = useNavigate()

  const [isSheetOpen, setIsSheetOpen] = useState(false)
  const openSheet = () => {
    setIsSheetOpen(true)
  }

  const closeSheet = () => {
    setIsSheetOpen(false)
  }

  const [status, setStatus] = useState(Status.Ready)
  const [amount, setAmount] = useState('0')
  const [isValid, setIsValid] = useState(false)

  const [menuOpen, setMenuOpen] = useState(false)

  const { zipCode, updateZipCode, salesTaxRate } = useSalesTax()

  const salesTaxAmount = (salesTaxRate || 0) * Number(amount)

  function shakePriceDisplay() {
    setIsValid(true)
    setTimeout(() => {
      setIsValid(false)
    }, 500)
  }

  async function handleSubmit(e: any) {
    e.preventDefault()

    if (amount === '0') {
      shakePriceDisplay()
      return
    }
    setStatus(Status.Loading)

    const res = await fetch(
      'https://payments-api-c560.onrender.com/api/v1/payment_requests',
      {
        method: 'POST',
        body: JSON.stringify({
          total: {
            currency: 'USDC',
            value: (Number(amount) + salesTaxAmount).toFixed(2),
          },
          display_items: [
            {
              label: 'Sub-total',
              type: 'item',
              value: amount,
            },
            {
              label: 'Sales Tax',
              type: 'tax',
              value: salesTaxAmount.toFixed(2),
            },
          ],
        }),
        headers: {
          'Content-Type': 'application/json',
          //  'X-TERMINAL-KEY': 'TEST',
          'X-API-KEY': API_KEY,
        },
      },
    )

    const { id } = await res.json()

    navigate(`/scan?id=${id}&source=terminal`)
    // openSheet()
  }

  const ZIP_CODE = '12345'

  useEffect(() => {
    updateZipCode(ZIP_CODE)
  }, [ZIP_CODE])

  if (status === Status.Loading) {
    // return <Loading />
  }

  if (menuOpen) {
    return (
      <div>
        <MenueMobile
          onClick={() => setMenuOpen(false)}
          zipCode={zipCode}
          salesTaxRate={salesTaxRate}
        />
      </div>
    )
  }

  return (
    <main className="fixed top-0 left-0 right-0 bottom-0">
      <div className="h-screen max-w-md mx-auto flex flex-col justify-start md:justify-center pt-10 md:pt-0 px-1 z-10">
        <div className="flex justify-center mx-auto mb-2 md:mb-5">
          <ShakeAnimation trigger={isValid}>
            <PriceTicker amount={amount} />
          </ShakeAnimation>
        </div>

        <div className="w-full flex justify-center py-8 md:py-5">
          <SelectCurrencyFiat />
        </div>

        <section className="mt-0 md:mt-12">
          <Keypad
            onInvalid={() => {
              setIsValid(true)
              setTimeout(() => setIsValid(false), 500)
            }}
            setAmount={(value) => {
              if (!value) return

              setAmount(value)
            }}
          />

          <div className="mt-2 md:mt-12">
            <EnterButton onClick={handleSubmit}>ENTER</EnterButton>
          </div>
        </section>

        <button
          onClick={() => {
            setMenuOpen(true)
          }}
          className="p-3 absolute right-2 top-10 md:hidden"
        >
          <EllipsisVerticalIcon className="w-6 h-6" />
        </button>

        <SheetView isOpen={isSheetOpen} onClose={closeSheet} />
      </div>

      <DesktopNavBar />
      <BottomNavigation />
    </main>
  )
}

const EnterButton = ({
  children,
  onClick,
}: {
  children: React.ReactNode
  onClick: (e: any) => Promise<void>
}) => {
  //const onClick = React.useCallback(() => onInput(input), [onInput, input])

  const [isClicked, setIsClicked] = useState(false)

  const handleClick = useCallback(
    (e: any) => {
      onClick(e)
      setIsClicked(true)
      setTimeout(() => setIsClicked(false), 150)
    },
    [onClick],
  )

  const variants: Variants = {
    clicked: { scale: 1.4 },
    unclicked: { scale: 1 },
  }

  return (
    <motion.button
      className={classNames(
        'select-none',
        'mx-auto',
        'bg-neutral-900',
        'flex',
        'items-center',
        'justify-center',
        'text-gray-100',
        //  'py-5',
        'rounded-md',
        'transition',
        'duration-150',
        'shadow',
        'bg-white',
        'dark:bg-black',
        'text-neutral-300',
        'dark:text-neutral-300',
        'h-14',
        'font-medium',
        //'text-base',
        'w-full',
        'dark:border-neutral-900',
        'border-0',
        'text-xl',
      )}
      type="button"
      onClick={handleClick}
      variants={variants}
      animate={isClicked ? 'clicked' : 'unclicked'}
    >
      {children}
    </motion.button>
  )
}
