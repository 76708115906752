import classNames from 'classnames'

import { formatNetworkName } from '../../utils'

import { Network } from '../../types'

const buttonClassNames = classNames(
  'font-medium',
  'min-w-full',
  'mx-auto',
  'flex',
  'items-center',
  'justify-center',
  'text-gray-600',
  'rounded-md',
  'space-x-2',
  'transition',
  'duration-150',
  'mt-4',
  'shadow',
  'bg-white',
  'dark:bg-slate-50',
  'text-gray-500',
  'dark:text-black',
  'h-14',
  'text-base',
  'border-neutral-800',
  'border',
)

interface Props {
  network: Network
  switchChains: (chainId: Network) => void
}

export default function ConnectChainButton({ switchChains, network }: Props) {
  return (
    <div className="max-w-sm mx-auto h-screen flex flex-col justify-center items-center min-h-screen p-5">
      <button
        // disabled={Number(balance) < Number(payment?.amount.value)}
        onClick={() => switchChains(network)}
        className={buttonClassNames}
      >
        {network === Network.POLYGON && (
          <img
            alt="Polygon Logo"
            src="https://opensea.io/static/images/logos/polygon.svg"
            width={15}
            height={15}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
            className="mr-2"
          />
        )}
        {network === Network.GOERLI && (
          <img
            alt="Polygon Logo"
            src="https://opensea.io/static/images/logos/ethereum.svg"
            width={15}
            height={15}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
            className="mr-2"
          />
        )}
        {network === Network.BASE_GOERLI && (
          <img
            alt="Polygon Logo"
            src="https://mirror-media.imgix.net/publication-images/cgqxxPdUFBDjgKna_dDir.png?h=1200&w=1200"
            width={15}
            height={15}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
            className="mr-2"
          />
        )}
        {network === Network.OPTIMISM_GOERLI && (
          <img
            alt="Optimism Logo"
            src="https://opensea.io/static/images/logos/optimism.svg"
            width={18}
            height={18}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
            className="mr-2"
          />
        )}
        {network === Network.POLYGON_MUMBAI && (
          <img
            alt="Polygon Logo"
            src="https://opensea.io/static/images/logos/polygon.svg"
            width={15}
            height={15}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
            className="mr-2"
          />
        )}
        Connect to {formatNetworkName(network)}
      </button>
    </div>
  )
}
