import { motion } from 'framer-motion'
import { CheckoutSession } from '../../../types'
import { DotAnimation } from '../../../components'

// import Lottie from 'react-lottie'
// import * as animationData from './pinjump.json'

interface Props {
  checkoutSession: CheckoutSession
}

export default function ApprovalPending({ checkoutSession }: Props) {
  /*
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
*/
  return (
    <div className="flex flex-col justify-center items-center min-h-screen">
      <div className="flex flex-col justify-center items-center">
        {/*<div className="shimmer-container-loading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-20 h-20 text-neutral-800 "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
            />
          </svg>
  </div>*/}
        <DotAnimation text="Approving.." />

        {/*<Lottie
          options={defaultOptions}
          height={'100vh'}
          width={'100vw'}
          isStopped={false}
          isPaused={false}
/>*/}
        {/*<p className="mt-12 font-semibold">Approving</p>*/}
      </div>
      {/* <DotAnimation />*/}
      <p>{/*formatAddress(payment?.transactionHash ?? '') FIXMR*/}</p>
    </div>
  )
}
