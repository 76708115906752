import { useContext, useMemo } from 'react'

import { TaxContext } from '../contexts'

interface State {
  // salesTaxAmount: number
  zipCode: string
  updateZipCode(zipcode: string): void
  salesTaxRate: number
}

export function useSalesTax(): State {
  const { salesTaxRate, zipCode, updateZipCode } = useContext(TaxContext)

  /*
  const salesTaxAmount = useMemo(() => {
    return (salesTaxRate || 0) * Number(amount)
    //return formatCurrencyAmount((salesTaxRate || 0) * Number(amount))
  }, [salesTaxRate, amount])
*/
  return {
    //  salesTaxAmount,
    zipCode,
    updateZipCode,
    salesTaxRate,
  }
}
