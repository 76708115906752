export default function SelectCurrencyFiat() {
  return (
    <div className="relative w-28 inline-flex rounded-2xl shadow-sm">
      <select className="block appearance-none w-28 py-2 px-5 pr-10 text-base leading-6 rounded-2xl focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5 bg-neutral-600 text-greeeen font-bold">
        <option>USDC</option>
        <option>EUROC</option>
      </select>
      <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
        <svg
          className="w-4 h-4 text-greeeen"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path fillRule="evenodd" d="M10 12l-6-6h12l-6 6z" />
        </svg>
      </div>
    </div>
  )
}
