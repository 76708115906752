import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { parseISO, format } from 'date-fns'
// import { PaymentStatusBadge,  } from '../../components'

import { PaperClipIcon } from '@heroicons/react/20/solid'
import { API_KEY } from '../../constants'
import { PaymentRequest, ChainId } from '../../types'
import {
  NetworkIcon,
  DesktopNavBar,
  PaymentStatusBadge,
  MotionButton,
} from '../../components'

import {
  makeExplorerLink,
  ExplorerType,
  formatAddress,
  chainName,
} from '../../utils'

export default function ActivityDetail() {
  let { paymentRequestId } = useParams()

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>()

  async function capture() {
    try {
      const res = await fetch(
        `https://payments-api-c560.onrender.com/api/v1/payment_requests/${paymentRequestId}/capture`,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'X-API-KEY': API_KEY,
            'Content-Type': 'application/json',
          },
        },
      )
      if (!res.ok) throw new Error('failed to fetch..')
    } catch (err) {
      alert(err)
      console.error(err)
    }
  }

  async function fetchPaymentRequest() {
    try {
      const res = await fetch(
        `https://payments-api-c560.onrender.com/api/v1/payment_requests/${paymentRequestId}`,
        {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'X-API-KEY': API_KEY,
            'Content-Type': 'application/json',
          },
        },
      )
      if (!res.ok) throw new Error('failed to fetch..')

      const data = await res.json()

      setPaymentRequest(data)
    } catch (err) {
      alert(err)
      console.error(err)
    }
  }

  useEffect(() => {
    fetchPaymentRequest()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="max-w-screen-2xl mx-auto px-12">
      <h3 className="text-xl mb-5 mt-5">Activity Details</h3>
      <div className="flex flex-row justify-between mb-5">
        <div className="flex flex-row mb-5 mt-5">
          <MotionButton
            variants={{
              clicked: { scale: 1.01 },
              unclicked: { scale: 1 },
            }}
            className="ml-0"
            onClick={() => {}}
          >
            <h3 className="text-xl text-neutral-500">{paymentRequest?.id}</h3>
            <PaperClipIcon className="w-5 h-5 ml-2 text-neutral-500" />
          </MotionButton>
        </div>

        <h3 className="text-2xl mb-5 mt-5">
          {' '}
          {paymentRequest?.total?.value} {paymentRequest?.total?.currency}
        </h3>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-black rounded-lg border border-neutral-950">
          <div className="flex flex-row justify-between mb-10">
            <p>Last updated</p>
            <p>
              {' '}
              {paymentRequest?.updated_at &&
                format(
                  parseISO(paymentRequest?.updated_at),
                  'MMMM dd, yyyy - hh:mm:ss a',
                )}
            </p>
          </div>

          <div className="flex flex-row justify-between mt-4">
            <p>Network</p>
            <div className="flex flex-row">
              <NetworkIcon chainId={paymentRequest?.chain_id} />
              <p className="ml-2">
                {' '}
                {chainName(paymentRequest?.chain_id as ChainId)}
              </p>
            </div>
          </div>

          <div className="flex flex-row justify-between mt-4">
            <p>Status</p>
            <PaymentStatusBadge status={paymentRequest?.status} />
          </div>

          <h3 className="mt-24 font-semibold">Summary</h3>
          <hr className="mt-2 border-1 border-neutral-900" />

          <div className="width-full flex flex-col mt-5">
            {paymentRequest?.display_items?.map((item) => (
              <div className="width-full flex flex-row justify-between mb-2 items-center text-neutral-400">
                <p>{item.label}</p>
                <p>{Number(item.value).toFixed(2)}</p>
              </div>
            ))}
          </div>

          <div className="flex flex-row justify-between font-semibold mb-10">
            <p>Total</p>
            <p>
              {paymentRequest?.total?.value} {paymentRequest?.total?.currency}
            </p>
          </div>

          <h3 className="mt-24 font-semibold">Shipping</h3>
          <hr className="mt-2 border-1 border-neutral-900" />

          <div className="flex flex-row justify-end mt-5">
            {paymentRequest?.status === 'APPROVED' && (
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                onClick={capture}
              >
                Capture
              </button>
            )}
          </div>

          {/*<div className="flex flex-row justify-between mt-5">
            <p>Currency</p>
            <div className="flex flex-row items-center">
              <img
                className="w-4 h-4"
                src="https://wallet-asset.matic.network/img/tokens/usdc.svg"
              />
              <p className="ml-2 font-semibold">
                {paymentRequest?.total?.currency}
              </p>
            </div>
          </div>*/}
        </div>

        <div className="bg-black p-12 rounded-lg border border-neutral-950 text-neutral-500">
          <h3 className="font-semibold text-white">References</h3>
          <hr className="mt-2 border-1 border-neutral-900" />

          <div className="width-full flex flex-col mt-8">
            <h3 className="mb-3 font-semibold text-white">Approval</h3>

            <div className="flex flex-row justify-between">
              <p>Hash</p>
              <a
                className="text-black-600 dark:text-neutral-500 hover:underline"
                href={makeExplorerLink({
                  chainId: paymentRequest?.chain_id,
                  hash: paymentRequest?.transactions?.approve_tx.hash,
                  type: ExplorerType.Hash,
                })}
              >
                {formatAddress(paymentRequest?.transactions?.approve_tx.hash)}{' '}
              </a>
            </div>

            <div className="flex flex-row justify-between">
              <p>From</p>

              <a
                className="text-black-600 dark:text-black-500 hover:underline"
                href={makeExplorerLink({
                  chainId: paymentRequest?.chain_id,
                  hash: paymentRequest?.transactions?.approve_tx.from,
                  type: ExplorerType.Address,
                })}
              >
                {formatAddress(paymentRequest?.transactions?.approve_tx.from)}
              </a>
            </div>

            <div className="flex flex-row justify-between">
              <p>To</p>

              <a
                className="text-black-600 dark:text-black-500 hover:underline"
                href={makeExplorerLink({
                  chainId: paymentRequest?.chain_id,
                  hash: paymentRequest?.transactions?.approve_tx.to,
                  type: ExplorerType.Token,
                })}
              >
                {formatAddress(paymentRequest?.transactions?.approve_tx.to)}
              </a>
            </div>
          </div>

          <div className="width-full flex flex-col mt-8">
            <h3 className="mb-3 font-semibold text-white">Capture</h3>

            <div className="flex flex-row justify-between">
              <p>Hash</p>
              <a
                className="text-black-600 dark:text-black-500 hover:underline"
                href={makeExplorerLink({
                  chainId: paymentRequest?.chain_id,
                  hash: paymentRequest?.transactions?.transfer_from_tx.hash,
                  type: ExplorerType.Hash,
                })}
              >
                {formatAddress(
                  paymentRequest?.transactions?.transfer_from_tx.hash,
                )}
              </a>
            </div>

            <div className="flex flex-row justify-between">
              <p>From</p>
              <a
                className="text-black-600 dark:text-black-500 hover:underline"
                href={makeExplorerLink({
                  chainId: paymentRequest?.chain_id,
                  hash: paymentRequest?.transactions?.transfer_from_tx.from,
                  type: ExplorerType.Address,
                })}
              >
                {formatAddress(
                  paymentRequest?.transactions?.transfer_from_tx.from,
                )}
              </a>
            </div>

            <div className="flex flex-row justify-between">
              <p>To</p>

              <a
                className="text-black-600 dark:text-black-500 hover:underline"
                href={makeExplorerLink({
                  chainId: paymentRequest?.chain_id,
                  hash: paymentRequest?.transactions?.transfer_from_tx.to,
                  type: ExplorerType.Token,
                })}
              >
                {formatAddress(
                  paymentRequest?.transactions?.transfer_from_tx.to,
                )}
              </a>
            </div>
          </div>

          <div className="width-full flex flex-col mt-8">
            <h3 className="mb-3 font-semibold text-white">Recipt</h3>

            <div className="flex flex-row justify-between">
              <p>ID</p>
              <p>1</p>
            </div>

            <div className="flex flex-row justify-between">
              <p>Hash</p>
              <p>
                <a
                  className="text-black-600 dark:text-black-500 hover:underline"
                  href={makeExplorerLink({
                    chainId: paymentRequest?.chain_id,
                    hash: paymentRequest?.transactions?.transfer_from_tx.hash,
                    type: ExplorerType.Hash,
                  })}
                >
                  {formatAddress(
                    paymentRequest?.transactions?.transfer_from_tx.hash,
                  )}
                </a>
              </p>
            </div>

            <div className="flex flex-row justify-between">
              <p>From</p>
              <a
                className="text-black-600 dark:text-black-500 hover:underline"
                href={makeExplorerLink({
                  chainId: paymentRequest?.chain_id,
                  hash: paymentRequest?.transactions?.transfer_from_tx.from,
                  type: ExplorerType.Address,
                })}
              >
                {formatAddress(
                  paymentRequest?.transactions?.transfer_from_tx.from,
                )}
              </a>
            </div>

            <div className="flex flex-row justify-between">
              <p>To</p>

              <a
                className="text-black-600 dark:text-black-500 hover:underline"
                href={makeExplorerLink({
                  chainId: paymentRequest?.chain_id,
                  hash: paymentRequest?.transactions?.transfer_from_tx.to,
                  type: ExplorerType.Token,
                })}
              >
                {formatAddress(
                  paymentRequest?.transactions?.transfer_from_tx.to,
                )}
              </a>
            </div>
          </div>

          <div className="width-full flex flex-col mt-8">
            <h3 className="mb-3 font-semibold text-white">Discount</h3>

            <div className="flex flex-row justify-between">
              <p>ID</p>
              <p>1</p>
            </div>

            <div className="flex flex-row justify-between">
              <p>Hash</p>
              <a
                className="text-black-600 dark:text-black-500 hover:underline"
                href={makeExplorerLink({
                  chainId: paymentRequest?.chain_id,
                  hash: paymentRequest?.transactions?.transfer_from_tx.hash,
                  type: ExplorerType.Hash,
                })}
              >
                {formatAddress(
                  paymentRequest?.transactions?.transfer_from_tx.hash,
                )}
              </a>
            </div>

            <div className="flex flex-row justify-between">
              <p>From</p>
              <a
                className="text-black-600 dark:text-black-500 hover:underline"
                href={makeExplorerLink({
                  chainId: paymentRequest?.chain_id,
                  hash: paymentRequest?.transactions?.transfer_from_tx.from,
                  type: ExplorerType.Address,
                })}
              >
                {formatAddress(
                  paymentRequest?.transactions?.transfer_from_tx.from,
                )}
              </a>
            </div>

            <div className="flex flex-row justify-between">
              <p>To</p>

              <a
                className="text-black-600 dark:text-black-500 hover:underline"
                href={makeExplorerLink({
                  chainId: paymentRequest?.chain_id,
                  hash: paymentRequest?.transactions?.transfer_from_tx.to,
                  type: ExplorerType.Token,
                })}
              >
                {formatAddress(
                  paymentRequest?.transactions?.transfer_from_tx.to,
                )}
              </a>
            </div>
          </div>
        </div>
      </div>

      <DesktopNavBar />
      <pre>{/*JSON.stringify(paymentRequest, null, 4)*/}</pre>
    </div>
  )
}
