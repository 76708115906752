/*
 * 0.00 -> 0, 10.00 -> 10, 0.01 -> 0.01
 */
export function trimTrailingZeros(amount: string) {
  if (!amount) return ''
  if (amount === '0') return '0'

  const [head, tail] = amount.split('.')

  const tailTotal = tail
    .split('')
    .map(Number)
    .reduce((acc, curr) => acc + curr, 0)

  if (tailTotal === 0) {
    return head
  }

  return `${head}.${tail}`
}

export function formatCurrencyAmount(
  amount: string | number = '',
  currency: string = 'USD',
) {
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  })

  return numberFormat.format(Number(amount))
}
