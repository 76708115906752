import { useState } from 'react'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import {
  ArrowsUpDownIcon,
  TagIcon,
  ArrowsPointingOutIcon,
  CalendarDaysIcon,
  ClockIcon,
  // Cog8ToothIcon,
} from '@heroicons/react/20/solid'

import { Cog8ToothIcon } from '@heroicons/react/24/outline'
export default function DesktopNavBar() {
  return (
    <div className="hidden sm:flex flex-col items-center w-16 h-full overflow-hidden text-gray-400 bg-black rounded fixed right-0 top-0 bottom-0">
      <a className="flex items-center justify-center mt-3" href="#">
        <p className="text-2xl">III</p>
      </a>
      <div className="flex flex-col items-center mt-20">
        <NavLink
          to="/"
          className={({ isActive, isPending }) =>
            classNames(
              'flex items-center justify-center w-12 h-12 mt-2  rounded',
              isPending ? 'pending' : isActive ? 'text-white' : '',
            )
          }
        >
          <ArrowsUpDownIcon className="w-6 h-6" />
        </NavLink>

        <NavLink
          to="/activity"
          className={({ isActive, isPending }) =>
            classNames(
              'flex items-center justify-center w-12 h-12 mt-2  rounded',
              isPending ? 'pending' : isActive ? 'text-white' : '',
            )
          }
        >
          <ClockIcon className="w-6 h-6" />
        </NavLink>

        <NavLink
          to="/settings"
          className={({ isActive, isPending }) =>
            classNames(
              'flex items-center justify-center w-12 h-12 mt-2  rounded',
              isPending ? 'pending' : isActive ? 'text-white' : '',
            )
          }
        >
          <Cog8ToothIcon className="w-6 h-6" />
        </NavLink>

        {/*<NavLink
          to="/coupons"
          className={({ isActive, isPending }) =>
            classNames(
              'flex items-center justify-center w-12 h-12 mt-2  rounded',
              isPending
                ? 'pending'
                : isActive
                ? 'text-white bg-neutral-600'
                : '',
            )
          }
        >
          <TagIcon className="w-6 h-6" />
        </NavLink>*/}
      </div>
      <a
        className="flex items-center justify-center w-16 h-16 mt-auto hover:bg-neutral-600 hover:text-gray-300"
        href="#"
      >
        <ArrowsPointingOutIcon className="w-6 h-6" />
      </a>
    </div>
  )
}
