import React, { useState, useCallback, useEffect } from 'react'
import { useQuery } from '../../../hooks'
import { ethers, BigNumber } from 'ethers'
import classNames from 'classnames'
import { Helmet } from 'react-helmet'
import Keypad from '../../Home/keypad'
import PriceTicker from '../../Home/PriceTicker'
import { motion, Variants } from 'framer-motion'

import { Network, networkToChainId } from '../../../types'

async function transferERC20Token({
  tokenAddress,
  recipientAddress,
  amount,
  signer,
}: {
  tokenAddress: string
  recipientAddress: string
  amount: ethers.BigNumber
  signer: ethers.providers.JsonRpcSigner
}) {
  // Get the ERC20 contract instance
  const erc20Contract = new ethers.Contract(
    tokenAddress,
    ['function transfer(address to, uint256 amount) public'],
    signer,
  )

  // Perform the token transfer
  const transaction = await erc20Contract.transfer(recipientAddress, amount)

  return transaction
}

enum ViewState {
  Loading,
  Ready,
  Error,
}

const EnterButton = ({
  children,
  onClick,
}: {
  children: React.ReactNode
  onClick: (e: any) => Promise<void>
}) => {
  //const onClick = React.useCallback(() => onInput(input), [onInput, input])

  const [isClicked, setIsClicked] = useState(false)

  const handleClick = useCallback(
    (e: any) => {
      onClick(e)
      setIsClicked(true)
      setTimeout(() => setIsClicked(false), 150)
    },
    [onClick],
  )

  const variants: Variants = {
    clicked: { scale: 1.4 },
    unclicked: { scale: 1 },
  }

  return (
    <motion.button
      className={classNames(
        'select-none',
        'mx-auto',
        'bg-neutral-900',
        'flex',
        'items-center',
        'justify-center',
        'text-gray-100',
        //  'py-5',
        'rounded-md',
        'transition',
        'duration-150',
        'shadow',
        'bg-white',
        'dark:bg-black',
        'text-neutral-300',
        'dark:text-neutral-300',
        'h-14',
        'font-medium',
        //'text-base',
        'w-full',
        'dark:border-neutral-900',
        'border-0',
        'text-xl',
      )}
      type="button"
      onClick={handleClick}
      variants={variants}
      animate={isClicked ? 'clicked' : 'unclicked'}
    >
      {children}
    </motion.button>
  )
}

export function Loading() {
  return (
    <div className="max-w-sm min-h-screen mx-auto flex flex-col justify-center items-center p-10">
      <div role="status">
        <svg
          aria-hidden="true"
          className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

export default function TransferRequest() {
  const [amount, setAmount] = useState('0.00')

  const [viewState, setViewState] = useState(ViewState.Ready)

  let query = useQuery()
  const to = query.get('to')
  const walletType = query.get('walletType')

  const USDC_POLYGON = '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'

  async function handleSubmit() {
    setViewState(ViewState.Loading)

    // Handle submit logic here, e.g., make an API request
    console.log(`Transfer ${amount} USDC to address ${to}`)

    try {
      if (!(window as any).ethereum) throw new Error('no window.ethereum')

      const provider = new ethers.providers.Web3Provider(
        (window as any).ethereum,
        'any',
      )

      if (!provider) {
        throw new Error('connect wallet webview - no provider')
      }
      const signer = provider.getSigner()
      if (!signer) throw new Error('no signer :(')

      const accounts = await (window as any).ethereum.request({
        method: 'eth_requestAccounts',
      })
      if (!accounts[0]) throw new Error('connect wallet webview - no account')

      const chainId = (await (window as any).ethereum.request({
        method: 'eth_chainId',
      })) as string

      if (!chainId) throw new Error('no chain_id')

      if (!to) throw new Error('no to')

      const transaction = await transferERC20Token({
        tokenAddress: USDC_POLYGON,
        recipientAddress: to,
        amount: ethers.utils.parseUnits(amount, 6),
        signer,
      })

      window.location.href = `bird://receive?tx=${transaction.hash}&chainID=137&walletType=${walletType}`

      // await transaction.wait()
      // setViewState(ViewState.Ready)

      console.log('Token transfer successful!')
    } catch (err) {
      console.error(err)
      setViewState(ViewState.Error)
    }
  }

  async function onMount() {
    if (!(window as any).ethereum) {
      throw new Error('[PAY:switchChains] no window.etherum')
    }
    const accounts = await (window as any).ethereum.request({
      method: 'eth_requestAccounts',
    })
    if (!accounts[0]) throw new Error('connect wallet webview - no account')

    console.log(networkToChainId[Network.POLYGON_MUMBAI])

    await (window as any).ethereum.request({
      method: `wallet_switchEthereumChain`,
      params: [
        {
          chainId: ethers.utils.hexlify(networkToChainId[Network.POLYGON]),
        },
      ],
    })
  }

  useEffect(() => {
    onMount()
  }, [])

  if (viewState === ViewState.Loading) {
    return <Loading />
  }

  return (
    <div className="h-screen max-w-md mx-auto p-3">
      <Helmet>
        <title>Transfer Request</title>
        <meta property="og:title" content="Payment Request" />
        <meta
          property="og:description"
          content="Description of your web page content"
        />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/opensea-prod.appspot.com/puffs/14.png"
        />
        <meta
          property="og:url"
          content="https://sdk-9y00.onrender.com/apple.html"
        />
      </Helmet>

      <div className="flex mt-3">
        {walletType === 'metamask' && (
          <div className="flex">
            {' '}
            <img
              alt="Metamask Logo"
              src="https://payments-webapp-assets-stage.s3.us-west-2.amazonaws.com/metamask-logo.svg"
              width={27}
              height={27}
              // className="mr-2"
              style={{
                maxWidth: '710px',
                height: 'auto',
              }}
            />
            <p className="px-3">MetaMask</p>
          </div>
        )}{' '}
        {walletType === 'coinbase' && (
          <div className="flex">
            {' '}
            <img
              alt="Metamask Logo"
              src="https://payments-webapp-assets-stage.s3.us-west-2.amazonaws.com/coinbase.png"
              width={27}
              height={27}
              // className="mr-2"
              style={{
                maxWidth: '710px',
                height: 'auto',
              }}
            />
            <p className="px-3">Coinbase</p>
          </div>
        )}
        <svg
          className="w-4 fill-gray-500 mr-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <path
            d="m31.71 15.29-10-10-1.42 1.42 8.3 8.29H0v2h28.59l-8.29 8.29 1.41 1.41 10-10a1 1 0 0 0 0-1.41z"
            data-name="3-Arrow Right"
            className="w-10"
          />
        </svg>
        <img
          src="https://payments-webapp-assets-stage.s3.us-west-2.amazonaws.com/website/bird-light.png"
          className="w-6"
        />
        <p className="ml-3">Bird</p>
      </div>

      {/*<div className="flex text-lg mb-8 mt-4">
        <span className="text-white">{to?.slice(0, 4)}</span>
        <span className="text-gray-500">
          {to?.slice(4, 8)}
          ...
          {to?.slice(to.length - 8, to.length - 4)}
        </span>
        <span className="text-white">
          {to?.slice(to.length - 4, to.length)}
        </span>
  </div>*/}

      <div className="mb-20 mt-20">
        <PriceTicker amount={amount} />
      </div>

      <Keypad
        setAmount={(amount) => {
          if (amount) {
            setAmount(amount)
          }
        }}
        onInvalid={() => {}}
      />

      <div className="mt-5">
        <EnterButton onClick={handleSubmit}>Send</EnterButton>
      </div>
    </div>
  )
}
