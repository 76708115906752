import classNames from 'classnames'
import { CheckoutSession } from '../types'
import { isMobile } from '../utils'

const buttonClassNames = classNames(
  'font-medium',
  'min-w-full',
  'mx-auto',
  'flex',
  'items-center',
  'justify-center',
  'text-gray-600',
  'dark:text-gray-100',
  'rounded-md',
  'space-x-2',
  'transition',
  'duration-150',
  'mt-4',
  'shadow',
  'bg-white',
  'dark:bg-slate-50',
  'text-gray-500',
  'dark:text-neutral-500',
  'h-14',
  'text-base',
  'border-neutral-800',
  'border',
)

interface Props {
  checkoutSession: CheckoutSession | undefined
  isValid: () => boolean
}

const USDC = ''

export default function WalletsSolana({ checkoutSession, isValid }: Props) {
  if (!checkoutSession) return null

  /*
  const method = checkoutSession?.networks.find(
    (method) => method.type === 'solana',
  )

  if (!method) return null

  const paymentAddress = method.metadata.find(
    (data) => data.identifier === 'payment_address',
  )?.value
  const reference = method.metadata.find(
    (data) => data.identifier === 'reference',
  )?.value
  // if (!checkoutSession?.networks..solanaPaymentAddress) return null

  // if (!reference) return null
*/
  return (
    <div
      style={{
        minHeight: '300px',
      }}
      className="mt-5"
    >
      <button
        onClick={async () => {
          if (!isValid()) return

          if (!isMobile) {
            // alert('must be on mobile')
            //return
          }

          alert('Solanapay coming soon!')
          /*
          try {
            await fetch(`/api/v1/payment_requests/${checkoutSession.id}`, {
              method: 'PATCH',
              body: JSON.stringify({
                isSolanaPay: true,
              }),
              headers: {
                'Content-Type': 'application/json',
              },
            })

            window.location.href = `solana:${paymentAddress}?amount=${encodeURIComponent(
              checkoutSession.amount.value,
            )}&spl-token=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&reference=${encodeURIComponent(
              reference,
            )}&label=${encodeURIComponent(
              checkoutSession.soft_descriptor
                ? 'Pay ' + checkoutSession.soft_descriptor
                : '',
            )}&message=${encodeURIComponent(
              `Thanks for your order! 😊`,
            )}&memo=${encodeURIComponent('Order Id: ' + checkoutSession.id)}`
          } catch (err) {
            alert(err)
          }
          */
        }}
        className={buttonClassNames}
      >
        <div className="flex justify-center items-center">
          <img
            alt="Solana Pay Logo"
            src="/solana-light.svg"
            width={60}
            height={60}
            className="mr-4"
            style={{
              maxWidth: '710px',
              height: 'auto',
            }}
          />
        </div>
      </button>

      <button
        onClick={async () => {
          if (!isValid()) return

          if (!isMobile) {
            // alert('must be on mobile')
            //return
          }

          alert('Solanapay coming soon!')
          /*
          try {
            await fetch(`/api/v1/payment_requests/${checkoutSession.id}`, {
              method: 'PATCH',
              body: JSON.stringify({
                isSolanaPay: true,
              }),
              headers: {
                'Content-Type': 'application/json',
              },
            })

            window.location.href = `solana:${paymentAddress}?amount=${encodeURIComponent(
              checkoutSession.amount.value,
            )}&spl-token=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&reference=${encodeURIComponent(
              reference,
            )}&label=${encodeURIComponent(
              checkoutSession.soft_descriptor
                ? 'Pay ' + checkoutSession.soft_descriptor
                : '',
            )}&message=${encodeURIComponent(
              `Thanks for your order! 😊`,
            )}&memo=${encodeURIComponent('Order Id: ' + checkoutSession.id)}`
          } catch (err) {
            alert(err)
          }
          */
        }}
        className={buttonClassNames}
      >
        <div className="flex justify-center items-center">
          <img
            alt="Brave browser"
            src="https://cdn.icon-icons.com/icons2/2552/PNG/512/brave_browser_logo_icon_153013.png"
            width={22}
            height={22}
            className="mr-2"
            style={{
              maxWidth: '710px',
              height: 'auto',
            }}
          />
          <p className="">Brave</p>
        </div>
      </button>
    </div>
  )
}
// &memo=memo

// solana:2wBHmuCwR1cwuVFyJo4EUu8tz8EKFBHYbNNP3PZdxdBH?amount=0.01&spl-token=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&reference=EshnyiYfwxU5ieH8MYWNRaszmDVs8j8ZwPTLpKaF8VAs&label=xxx&message=msg&memo=memo
