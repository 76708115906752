import React, { useState } from 'react'
import classNames from 'classnames'
import { motion, Variants } from 'framer-motion'

interface Props {
  className: string
  onClick: () => void
  variants: Variants
  children: React.ReactNode
}

export default function MotionButton({
  className,
  onClick,
  children,
  variants,
}: Props) {
  const [isClicked, setIsClicked] = useState(false)

  const buttonClassNames = classNames(
    'flex',
    'my-2',
    //'md:m-2',
    'justify-center',
    'items-center',
    'text-3xl',
    'p-5',
    'w-full',
    'h-14',
    //'border-neutral-400',
    'shadow',
    //'md:h-14',
    'bg-neutral-950',
    'font-medium',
    'dark:bg-black',
    'text-white',
    'dark:text-neutral-300',
    'rounded-md',
    'dark:border-neutral-900',
    'border-0',
    'tap:bg-gray-300',
  )

  const handleClick = React.useCallback(() => {
    onClick()
    setIsClicked(true)
    setTimeout(() => setIsClicked(false), 150)
  }, [])

  return (
    <motion.button
      className={classNames(buttonClassNames, className)}
      type="button"
      onClick={handleClick}
      variants={variants}
      animate={isClicked ? 'clicked' : 'unclicked'}
    >
      {children}
    </motion.button>
  )
}
