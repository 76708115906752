import classNames from 'classnames'
import { CheckoutSession, Network, Wallet } from '../types'
import { useWeb3Modal } from '@web3modal/react'
import { useState, useEffect } from 'react'

import { isMobile, formatNetworkName } from '../utils'
import { Web3Button } from '@web3modal/react'
import {
  useAccount,
  useContract,
  useSigner,
  useDisconnect,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
} from 'wagmi'

const buttonClassNames = classNames(
  'font-medium',
  'min-w-full',
  'mx-auto',
  'flex',
  'items-center',
  'justify-center',
  'text-gray-600',
  'rounded-md',
  'space-x-2',
  'transition',
  'duration-150',
  'mt-4',
  'shadow',
  'bg-white',
  'dark:bg-slate-50',
  'text-gray-500',
  'dark:text-black',
  'h-14',
  'text-base',
  'border-neutral-800',
  'border',
)

interface ButtonProps {
  id: string
  isValid: () => boolean
  network: Network
  wallet: Wallet
}

function Button({ id, isValid, network, wallet }: ButtonProps) {
  let desktopURL = ''
  let mobileURL = ''

  switch (wallet) {
    case Wallet.MetaMask:
      desktopURL = `${window.location.origin}/pay?id=${id}&wallet=metamask&network=${network}`
      mobileURL = `https://metamask.app.link/dapp/${window.location.origin}/pay?id=${id}&wallet=metamask&network=${network}`
      break
    case Wallet.Coinbase:
      desktopURL = `${window.location.origin}/pay?id=${id}&wallet=coinbase&network=${network}`
      mobileURL = `https://go.cb-w.com/dapp?cb_url=${encodeURIComponent(
        `${window.location.origin}/pay?id=${id}&wallet=coinbase&network=${network}`,
      )}`
      break
    case Wallet.Rainbow:
      desktopURL = `${window.location.origin}/pay?id=${id}&wallet=coinbase&network=${network}`
      mobileURL = `https://go.cb-w.com/dapp?cb_url=${encodeURIComponent(
        `${window.location.origin}/pay?id=${id}&wallet=coinbase&network=${network}`,
      )}`
      break
    case Wallet.Brave:
      desktopURL = `${window.location.origin}/pay?id=${id}&wallet=brave&network=${network}`
      mobileURL = `brave://open-url?url=${encodeURIComponent(
        `${window.location.origin}/pay?id=${id}&wallet=coinbase&network=${network}`,
      )}`
      break
    case Wallet.Opera:
      desktopURL = `${window.location.origin}/pay?id=${id}&wallet=brave&network=${network}`
      mobileURL = `opera://open-url?url=${encodeURIComponent(
        `${window.location.origin}/pay?id=${id}&wallet=coinbase&network=${network}`,
      )}`
      break
  }

  return (
    <button
      onClick={() => {
        if (!isValid()) return

        if (
          !isMobile &&
          wallet === Wallet.MetaMask &&
          !(window as any).ethereum?.isMetaMask
        ) {
          alert('Metamask not connected')
          return
        }

        if (
          !isMobile &&
          wallet === Wallet.Brave &&
          !(window as any)?.ethereum?.isBraveWallet
        ) {
          alert('Not on Brave browser')
          return
        }

        if (
          window.location.href.includes('localhost') ||
          !isMobile ||
          !!(window as any).ethereum
        ) {
          window.location.href = desktopURL
        } else {
          window.location.href = mobileURL
        }

        /* window.location.replace(
        `https://metamask.app.link/dapp/${window.location.origin}/pay?id=${payment?.id}&wallet=metamask`,
        // https://metamask.app.link/dapp/terminal-app-yqbo.onrender.com/pay?id=ucYSSPlUgiKSoKCXPsdZU&wallet=metamask
      )*/
      }}
      className={buttonClassNames}
    >
      <div className="flex justify-center items-center">
        {wallet === Wallet.MetaMask && (
          <div className="flex justify-center items-center">
            <img
              alt="Metamask Logo"
              src="https://payments-webapp-assets-stage.s3.us-west-2.amazonaws.com/metamask-logo.svg"
              width={27}
              height={27}
              className="mr-2"
              style={{
                maxWidth: '710px',
                height: 'auto',
              }}
            />
            <p className=""> MetaMask</p>
          </div>
        )}
        {wallet === Wallet.Coinbase && (
          <div className="flex justify-center items-center">
            <img
              alt="Polygon Logo"
              // src="https://app.uniswap.org/static/media/coinbaseWalletIcon.a3a7d7fd.svg"
              src="https://payments-webapp-assets-stage.s3.us-west-2.amazonaws.com/coinbase.png"
              //src="https://app.uniswap.org/static/media/coinbaseWalletIcon.07499ce0.svg"
              width={20}
              height={20}
              className="mr-2"
              style={{
                maxWidth: '710px',
                height: 'auto',
              }}
            />
            <p className="">Coinbase Wallet</p>
          </div>
        )}

        {wallet === Wallet.Rainbow && (
          <div className="flex justify-center items-center">
            <img
              alt="Polygon Logo"
              src="https://payments-webapp-assets-stage.s3.us-west-2.amazonaws.com/rainbow.png"
              width={22}
              height={22}
              className="mr-2"
              style={{
                maxWidth: '710px',
                height: 'auto',
              }}
            />
            <p className="">Rainbow</p>
          </div>
        )}

        {wallet === Wallet.Brave && (
          <div className="flex justify-center items-center">
            <img
              alt="Brave browser"
              src="https://payments-webapp-assets-stage.s3.us-west-2.amazonaws.com/brave.png"
              width={22}
              height={22}
              className="mr-2"
              style={{
                maxWidth: '710px',
                height: 'auto',
              }}
            />
            <p className="">Brave</p>
          </div>
        )}

        {wallet === Wallet.Opera && (
          <div className="flex justify-center items-center">
            <img
              alt="Opera browser"
              src="https://payments-webapp-assets-stage.s3.us-west-2.amazonaws.com/opera.svg"
              width={20}
              height={20}
              className="mr-2"
              style={{
                maxWidth: '710px',
                height: 'auto',
              }}
            />
            <p className="">Opera</p>
          </div>
        )}
      </div>
    </button>
  )
}
//

interface Props {
  checkoutSession: CheckoutSession | undefined
  isValid: () => boolean
  network: Network
}

const wallets = [
  Wallet.Coinbase,
  Wallet.MetaMask,
  // Wallet.Rainbow,
  // Wallet.WalletConnect,
  Wallet.Brave,
  //  Wallet.Opera,
]

export default function WalletsEthereum({
  checkoutSession,
  isValid,
  network,
}: Props) {
  const [hasInitalized, setHasInitalized] = useState(false)
  // const { address, isConnected } = useAccount()
  const [loading, setLoading] = useState(false)
  const { open } = useWeb3Modal()
  const { address, isConnected } = useAccount()
  const { disconnect } = useDisconnect()
  const label = isConnected ? 'Disconnect' : 'Connect Custom'

  async function onOpen() {
    setLoading(true)
    await open()
    setLoading(false)
    setHasInitalized(true)
  }

  const walletConnectURL = `${window.location.origin}/pay?id=${checkoutSession?.id}&wallet=metamask&network=${network}`

  useEffect(() => {
    if (isConnected && checkoutSession?.id && hasInitalized) {
      window.location.href = walletConnectURL
    }
  }, [isConnected, hasInitalized, checkoutSession?.id])

  function onClick() {
    if (isConnected) {
      disconnect()
      onOpen()
    } else {
      onOpen()
    }
  }

  if (!checkoutSession) return null
  return (
    <div
      style={{
        minHeight: '300px',
      }}
      className="mt-5"
    >
      {/*[
        Network.GOERLI,
        Network.OPTIMISM_GOERLI,
        Network.BASE_GOERLI,
        Network.POLYGON_MUMBAI,
      ].includes(network) && (
        <>
          <p className="text-slate-300 text-sm mt-5 mb-5">
            {formatNetworkName(network)} test tokens can be obtained{' '}
            <a
              href={`https://faucet-vbkl.onrender.com?network=${network}`}
              target="_blank"
              rel="noreferrer"
              className="font-semibold text-white hover:underline"
            >
              here
            </a>
            .
          </p>
        </>
      )*/}

      {wallets.map((wallet) => (
        <Button
          key={wallet}
          id={checkoutSession?.id}
          isValid={isValid}
          network={network}
          wallet={wallet}
        />
      ))}

      {/*<Web3Button />*/}

      <button className={buttonClassNames} onClick={onClick} disabled={loading}>
        <div className="flex justify-center items-center">
          <img
            alt="Polygon Logo"
            src="https://raw.githubusercontent.com/WalletConnect/walletconnect-assets/86f13aa694139bdb1266d2d965dee10885186cd2/Logo/Blue%20(Default)/Logo.svg"
            width={30}
            height={30}
            className="mr-2"
            style={{
              maxWidth: '710px',
              height: 'auto',
            }}
          />
          <p className="">Wallet Connect</p>
        </div>

        {/*loading ? 'Loading...' : label} {address}*/}
      </button>
    </div>
  )
}
