import { useState, useEffect } from 'react'

import { ethers, Contract, BigNumber, Signer } from 'ethers'

interface Domain {
  name: string
  version: string
  verifyingContract: string
  chainId: number
}

interface SignerWithAddress extends Signer {
  _signTypedData: (
    domain: Domain,
    arg2: any,
    message: ERC2612PermitMessage,
  ) => Promise<string>
}

interface ERC2612PermitMessage {
  owner: string
  spender: string
  value: number | string
  nonce: BigNumber
  deadline: number | string
}

class ERC20PermitManager {
  contract: Contract
  signer: SignerWithAddress
  _domain: Domain | null = null
  name: string
  version: string

  constructor({
    contract,
    signer,
    name,
    version = '1',
  }: {
    contract: Contract
    signer: SignerWithAddress
    name: string
    version: string
  }) {
    this.contract = contract
    this.signer = signer
    this.name = name
    this.version = version
  }

  async createPermit({
    owner,
    spender,
    value,
    nonce,
    deadline,
  }: {
    owner: string
    spender: string
    value: number | string
    nonce: BigNumber
    deadline: number | string
  }) {
    const message: ERC2612PermitMessage = {
      owner,
      spender,
      value,
      nonce,
      deadline,
    }

    const domain = await this._signingDomain()

    const types = {
      Permit: [
        {
          name: 'owner',
          type: 'address',
        },
        {
          name: 'spender',
          type: 'address',
        },
        {
          name: 'value',
          type: 'uint256',
        },
        {
          name: 'nonce',
          type: 'uint256',
        },
        {
          name: 'deadline',
          type: 'uint256',
        },
      ],
    }

    // NOTE: going to be renamed to signTypedData in ethers.js later versions ..
    const signature = await this.signer._signTypedData(domain, types, message)

    return {
      ...message,
      signature,
    }
  }

  async _signingDomain() {
    if (this._domain != null) {
      return this._domain
    }

    let chainId: number | undefined

    try {
      chainId = await this.contract.getChainID()
    } catch (err) {
      chainId = 137
    }

    if (!chainId) throw new Error('no chainId')

    this._domain = {
      name: this.name,
      version: this.version,
      verifyingContract: this.contract.address,
      chainId,
    }
    return this._domain
  }
}

const USDC_PERMIT_MUMBAI = '0x38bcf5a89703dC164de722380485DDe11a5ce364'
const ONE_YEAR_IN_SECS = 365 * 24 * 60 * 60
const SPENDER = '0x8a45c1a4d2297052E46ea11d50C5a000900828a7'

export default function ERC20Permit() {
  async function permit() {
    const provider = new ethers.providers.Web3Provider(
      (window as any).ethereum,
      'any',
    )

    if (!provider) {
      throw new Error('connect wallet webview - no provider')
    }
    const signer = provider.getSigner()
    if (!signer) throw new Error('no signer :(')

    const contract = new Contract(
      USDC_PERMIT_MUMBAI,
      [
        {
          inputs: [],
          stateMutability: 'nonpayable',
          type: 'constructor',
        },
        {
          inputs: [],
          name: 'DOMAIN_SEPARATOR',
          outputs: [
            {
              internalType: 'bytes32',
              name: '',
              type: 'bytes32',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
          ],
          name: 'allowance',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'amount',
              type: 'uint256',
            },
          ],
          name: 'approve',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'account',
              type: 'address',
            },
          ],
          name: 'balanceOf',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'decimals',
          outputs: [
            {
              internalType: 'uint8',
              name: '',
              type: 'uint8',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'subtractedValue',
              type: 'uint256',
            },
          ],
          name: 'decreaseAllowance',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'getChainID',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'addedValue',
              type: 'uint256',
            },
          ],
          name: 'increaseAllowance',
          outputs: [
            {
              internalType: 'bool',
              name: '',
              type: 'bool',
            },
          ],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'mint',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [],
          name: 'name',
          outputs: [
            {
              internalType: 'string',
              name: '',
              type: 'string',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
          ],
          name: 'nonces',
          outputs: [
            {
              internalType: 'uint256',
              name: '',
              type: 'uint256',
            },
          ],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [
            {
              internalType: 'address',
              name: 'owner',
              type: 'address',
            },
            {
              internalType: 'address',
              name: 'spender',
              type: 'address',
            },
            {
              internalType: 'uint256',
              name: 'value',
              type: 'uint256',
            },
            {
              internalType: 'uint256',
              name: 'deadline',
              type: 'uint256',
            },
            {
              internalType: 'uint8',
              name: 'v',
              type: 'uint8',
            },
            {
              internalType: 'bytes32',
              name: 'r',
              type: 'bytes32',
            },
            {
              internalType: 'bytes32',
              name: 's',
              type: 'bytes32',
            },
          ],
          name: 'permit',
          outputs: [],
          stateMutability: 'nonpayable',
          type: 'function',
        },
      ],
      signer,
    )

    const accounts = await (window as any).ethereum.request({
      method: 'eth_requestAccounts',
    })
    if (!accounts[0]) throw new Error('connect wallet webview - no account')

    const chainId = (await (window as any).ethereum.request({
      method: 'eth_chainId',
    })) as string

    if (!chainId) throw new Error('no chain_id')

    const permitManager = new ERC20PermitManager({
      contract: contract,
      signer,
      name: await contract.name(),
      version: '1',
    })

    const AMOUNT = ethers.utils.parseUnits('1', await contract.decimals())

    const deadline = Date.now() + ONE_YEAR_IN_SECS

    const permit = await permitManager.createPermit({
      owner: accounts[0],
      spender: SPENDER,
      value: AMOUNT.toString(),
      nonce: await contract.nonces(accounts[0]),
      deadline,
    })

    console.log(JSON.stringify(permit, null, 4))
    /*
{
    "owner": "0x3d9a0ae80428f3dd58c14cc41d08128e305a1fa5",
    "spender": "0x8a45c1a4d2297052E46ea11d50C5a000900828a7",
    "value": "1000000000000000000",
    "nonce": {
        "type": "BigNumber",
        "hex": "0x00"
    },
    "deadline": 1682843006900,
    "signature": "0x97c095e2b74bf40a2d6781e8771540e7f1f30461f9788333ddabaf78d598e5f02781068394d4b311d93c89167706c9731110b8adb802209d093c43bf78ddc9501b"
}
*/

    const { r, s, v } = ethers.utils.splitSignature(permit.signature)

    const tx = await contract.permit(
      permit.owner,
      permit.spender,
      permit.value,
      permit.deadline,
      v,
      r,
      s,
    )

    console.log(JSON.stringify(tx, null, 4))
    /*
    {
    "hash": "0xcbf02d51685d5b24c61e35cb81caad41ee52d2016e908cf75eb5e5c544eef042",
    "type": 2,
    "accessList": [],
    "blockHash": null,
    "blockNumber": null,
    "transactionIndex": null,
    "confirmations": 0,
    "from": "0x3d9A0AE80428f3dd58c14Cc41D08128E305A1fa5",
    "gasPrice": {
        "type": "BigNumber",
        "hex": "0x0ba43b740e"
    },
    "maxPriorityFeePerGas": {
        "type": "BigNumber",
        "hex": "0x0ba43b7400"
    },
    "maxFeePerGas": {
        "type": "BigNumber",
        "hex": "0x0ba43b740e"
    },
    "gasLimit": {
        "type": "BigNumber",
        "hex": "0x012f4a"
    },
    "to": "0x38bcf5a89703dC164de722380485DDe11a5ce364",
    "value": {
        "type": "BigNumber",
        "hex": "0x00"
    },
    "nonce": 316,
    "data": "0xd505accf0000000000000000000000003d9a0ae80428f3dd58c14cc41d08128e305a1fa50000000000000000000000008a45c1a4d2297052e46ea11d50c5a000900828a70000000000000000000000000000000000000000000000000de0b6b3a764000000000000000000000000000000000000000000000000000000000187d1492369000000000000000000000000000000000000000000000000000000000000001c8b6d99544f1914fbea4465a981e597f380f580725fe27038ef6d24a572b2e9e1227cf8b282384c25a071a8d5fb36379121798353e007a8771e3be8fcfeaaed3b",
    "r": "0xc85bc2d72b169ff7cbf969fe0cf2c75b263d4e985f503226cc9e0b9b62918da3",
    "s": "0x5cdb0e28fd63e25a466a259518aad30aff10ab6268f273865b7c856427926f1f",
    "v": 0,
    "creates": null,
    "chainId": 80001
}
*/
  }

  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <p className="mb-8">ERC20 Permit</p>
      <button
        onClick={permit}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
      >
        Permit
      </button>
    </div>
  )
}
