import { Approved, ApprovalPending, LoadingSpinner } from '../Pay/Loading'
import Captured from '../Pay/Captured'
import { motion, Variants } from 'framer-motion'

import ReactModal from 'react-modal'
import { useState } from 'react'
import {
  CheckIcon,
  XMarkIcon,
  ViewfinderCircleIcon,
} from '@heroicons/react/20/solid'

import { ButtonAnimated, Fadeout, Switch } from '../../components'

import { DisplayItemType } from '../../types'

const checkoutSession = {
  id: 'pay_L2SceQepysc56SE3f8g7by',
  merchant_id: 'merch_L2SceQepysc56SE3f8g7by',
  status: 'CAPTURE_PENDING',
  total: { value: '1.08', currency: 'USDC' },
  display_items: [
    { key: '1', label: 'Items', type: DisplayItemType.ITEM, value: '1' },
    { key: '2', label: 'Sales Tax', type: DisplayItemType.TAX, value: '0.08' },
    {
      key: '3',
      label: 'Shipping',
      type: DisplayItemType.SHIPPING,
      value: '0.00',
    },
  ],
  soft_descriptor: 'Acme inc.',
  wallet: {
    chain_id: 137,
    address: '0x123456789abcdefg',
    native_balance: {
      value: 465569199987945300,
      decimals: 18,
    },
    tokens: [
      {
        symbol: 'USDC',
        name: 'USD Coin',
        balance: 108000000,
        decimals: 6,
      },
    ],
    coupons: [
      {
        name: '25.5% off',
        percent_off: 25.5,
        image: 'xxx',
        bp: 2500,
        uri: 'xxx',
        contract_address: '0x...',
        token_id: 1,
        data: 'xxxxx',
      },
    ],
    points: {
      value: 10000,
      decimals: 6,
    },
  },
  networks: [
    {
      type: 'blockchain',
      chain: 'polygon',
      display_name: 'Polygon',
      chain_id: 137,
      tokens: [
        {
          name: 'USD Coin',
          symbol: 'USDC',
          address: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
          decimals: 6,
          amount: 1080000,
          approve_data:
            '0x095ea7b30000000000000000000000008a45c1a4d2297052e46ea11d50c5a000900828a70000000000000000000000000000000000000000000000000000000000107ac0',
          approval_target: '0x8a45c1a4d2297052E46ea11d50C5a000900828a7',
          approval_tx: {
            to: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
            data:
              '0x095ea7b30000000000000000000000008a45c1a4d2297052e46ea11d50c5a000900828a70000000000000000000000000000000000000000000000000000000000107ac0',
            value: '0x0',
          },
        },
      ],
      metadata: null,
    },
    {
      type: 'blockchain',
      chain: 'goerli',
      display_name: 'Goerli',
      chain_id: 5,
      tokens: [
        {
          name: 'USD Coin test',
          symbol: 'USDCt',
          address: '0x9f2C34E913Db31A528769845DFe36517027637Cc',
          decimals: 6,
          amount: 1080000,
          approve_data:
            '0x095ea7b30000000000000000000000008a45c1a4d2297052e46ea11d50c5a000900828a70000000000000000000000000000000000000000000000000000000000107ac0',
          approval_target: '0x8a45c1a4d2297052E46ea11d50C5a000900828a7',
          approval_tx: {
            to: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
            data:
              '0x095ea7b30000000000000000000000008a45c1a4d2297052e46ea11d50c5a000900828a70000000000000000000000000000000000000000000000000000000000107ac0',
            value: '0x0',
          },
        },
      ],
      metadata: null,
    },
    {
      type: 'blockchain',
      chain: 'base-goerli',
      display_name: 'Base - goerli',
      chain_id: 84531,
      tokens: [
        {
          name: 'USD Coin test',
          symbol: 'USDCt',
          address: '0xe57d63A252545CCE217de2464d5E9027800EA6c1',
          decimals: 6,
          amount: 1080000,
          approve_data:
            '0x095ea7b30000000000000000000000008a45c1a4d2297052e46ea11d50c5a000900828a70000000000000000000000000000000000000000000000000000000000107ac0',
          approval_target: '0x8a45c1a4d2297052E46ea11d50C5a000900828a7',
          approval_tx: {
            to: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
            data:
              '0x095ea7b30000000000000000000000008a45c1a4d2297052e46ea11d50c5a000900828a70000000000000000000000000000000000000000000000000000000000107ac0',
            value: '0x0',
          },
        },
      ],
      metadata: null,
    },
    {
      type: 'blockchain',
      chain: 'optimism-goerli',
      display_name: 'Optimism - goerli',
      chain_id: 420,
      tokens: [
        {
          name: 'USD Coin test',
          symbol: 'USDCt',
          address: '0xe57d63A252545CCE217de2464d5E9027800EA6c1',
          decimals: 6,
          amount: 1080000,
          approve_data:
            '0x095ea7b30000000000000000000000008a45c1a4d2297052e46ea11d50c5a000900828a70000000000000000000000000000000000000000000000000000000000107ac0',
          approval_target: '0x8a45c1a4d2297052E46ea11d50C5a000900828a7',
          approval_tx: {
            to: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
            data:
              '0x095ea7b30000000000000000000000008a45c1a4d2297052e46ea11d50c5a000900828a70000000000000000000000000000000000000000000000000000000000107ac0',
            value: '0x0',
          },
        },
      ],
      metadata: null,
    },
    {
      type: 'blockchain',
      chain: 'polygon-mumbai',
      display_name: 'Polygon - mumbai',
      chain_id: 80001,
      tokens: [
        {
          name: 'USD Coin test',
          symbol: 'USDCt',
          address: '0xe57d63A252545CCE217de2464d5E9027800EA6c1',
          decimals: 6,
          amount: 1080000,
          approve_data:
            '0x095ea7b30000000000000000000000008a45c1a4d2297052e46ea11d50c5a000900828a70000000000000000000000000000000000000000000000000000000000107ac0',
          approval_target: '0x8a45c1a4d2297052E46ea11d50C5a000900828a7',
          approval_tx: {
            to: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
            data:
              '0x095ea7b30000000000000000000000008a45c1a4d2297052e46ea11d50c5a000900828a70000000000000000000000000000000000000000000000000000000000107ac0',
            value: '0x0',
          },
        },
      ],
      metadata: null,
    },
    {
      type: 'blockchain',
      chain: 'solana',
      display_name: 'Solana',
      chain_id: 0,
      tokens: [
        {
          name: 'USD Coin',
          symbol: 'USDC',
          address: '0x',
          decimals: 6,
          amount: 1080000,
          approve_data:
            '0x095ea7b30000000000000000000000008a45c1a4d2297052e46ea11d50c5a000900828a70000000000000000000000000000000000000000000000000000000000107ac0',
          approval_target: '0x8a45c1a4d2297052E46ea11d50C5a000900828a7',
          approval_tx: {
            to: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
            data:
              '0x095ea7b30000000000000000000000008a45c1a4d2297052e46ea11d50c5a000900828a70000000000000000000000000000000000000000000000000000000000107ac0',
            value: '0x0',
          },
        },
      ],
      metadata: [
        {
          identifier: 'payment_address',
          value: '2VBbaTDvxhS9thjnzgXJhQvnv2UcXTYCsRgKcwNyeQom',
        },
        {
          identifier: 'reference',
          value: '2VBbaTDvxhS9thjnzgXJhQvnv2UcXTYCsRgKcwNyeQom',
        },
      ],
    },
  ],
  transactions: {
    approve_tx: {
      type: 2,
      chainId: 80001,
      nonce: 101,
      to: '0xe57d63A252545CCE217de2464d5E9027800EA6c1',
      data: '',
      hash:
        '0xc0d93b5dc1fa36ea249a9b03511f2938c5801c1ad6ff93923384c4e73b7d8936',
      from: '0x3d9A0AE80428f3dd58c14Cc41D08128E305A1fa5',
      confirmations: 0,
    },
    transfer_from_tx: {
      type: 0,
      chainId: 80001,
      nonce: 295,
      to: '0xe57d63A252545CCE217de2464d5E9027800EA6c1',
      data:
        '#�r�\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000=�\n�\u0004(��X�L�\u001d\u0008\u0012�0Z\u001f�\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000�E���)pR�n�\u001dPŠ\u0000�\u0008(�\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0010z�',
      hash:
        '0x80d36822a0b381ebf9c4e98226e316123ab0293af81990d22208af413e55a221',
      from: 'tx.From().String()',
      confirmations: 0,
    },
    coupon_burn_tx: null,
  },
  coupons: [
    {
      name: 'NAME',
      image:
        'https://storage.googleapis.com/opensea-prod.appspot.com/puffs/3.png',
      bp: 1000,
      uri:
        'https://payments-api-c560.onrender.com/nft/metadata?name=NAME\u0026description=DESCRIPTION\u0026image=https%3A%2F%2Fcodepromo.coupons.com%2Fimages%2F1800x%2F%2Fimages%2Fm%2FMacys_logo.png',
      contract_address: '0x4E8660A68Dd0f7A9Ac37B34d1D26C77b9d7DdB95',
      token_id: 109,
      data:
        '0x42966c68000000000000000000000000000000000000000000000000000000000000006d',
    },
  ],
  rewards: [
    {
      id: 'rwrd_mMfQLz95P6NbfgfgQYZVu9',
      label: 'FREE curly fries',
      points_required: 100,
      image:
        'https://storage.googleapis.com/opensea-prod.appspot.com/puffs/11.png',
      burn_tx: {
        to: '0x7243440b41F4112b22e92680b57089826A8a4D11',
        data:
          '0x42966c680000000000000000000000000000000000000000000000000000000000000064',
        value: '0x0',
      },
    },
    {
      id: 'rwrd_aqxeFmxpMSKbkhdEw3DDQv',
      label: 'Free Fountain Drink',
      points_required: 20,
      image:
        'https://storage.googleapis.com/opensea-prod.appspot.com/puffs/14.png',
      burn_tx: {
        to: '0x7243440b41F4112b22e92680b57089826A8a4D11',
        data:
          '0x42966c680000000000000000000000000000000000000000000000000000000000000064',
        value: '0x0',
      },
    },
    {
      id: 'rwrd_gPscHh29RA9g5FNV3xJcWh',
      label: '$10 Off Your Next Purchase',
      points_required: 100,
      image:
        'https://storage.googleapis.com/opensea-prod.appspot.com/puffs/16.png',
      burn_tx: {
        to: '0x7243440b41F4112b22e92680b57089826A8a4D11',
        data:
          '0x42966c680000000000000000000000000000000000000000000000000000000000000064',
        value: '0x0',
      },
    },
  ],
}

export default function Design() {
  const [showModal, setShowModal] = useState(false)

  const [show, setShow] = useState(true)

  function handleOpenModal() {
    setShowModal(true)
  }

  function handleCloseModal() {
    setShowModal(false)
  }

  const spring = {
    type: 'spring',
    damping: 10,
    stiffness: 100,
  }

  return (
    <>
      <Fadeout isVisible={show}>
        <h1>elllo</h1>
        <button onClick={() => setShow(!show)}>Hide</button>
      </Fadeout>

      <section className="mb-20 mt-20 container mx-auto">
        <Switch />
      </section>

      <section className="mb-20 mt-20">
        <div className="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px]">
          <div className="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -left-[17px] top-[72px] rounded-l-lg"></div>
          <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -left-[17px] top-[124px] rounded-l-lg"></div>
          <div className="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -left-[17px] top-[178px] rounded-l-lg"></div>
          <div className="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -right-[17px] top-[142px] rounded-r-lg"></div>
          <div className="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white dark:bg-gray-800">
            <img
              // src="https://payments-webapp-assets-stage.s3.us-west-2.amazonaws.com/screenshot.png"
              src="https://payments-webapp-assets-stage.s3.us-west-2.amazonaws.com/phone-scan2.png"
              className="dark:hidden w-[272px] h-[572px] object-cover"
              alt=""
            />

            <img
              // src="https://payments-webapp-assets-stage.s3.us-west-2.amazonaws.com/screenshot.png"
              src="https://payments-webapp-assets-stage.s3.us-west-2.amazonaws.com/phone-scan2.png"
              className="hidden dark:block w-[272px] h-[572px] object-cover"
              alt=""
            />
          </div>
        </div>
      </section>

      <br />
      <br />
      <br />

      <div className="shadow rounded-md p-4 max-w-sm w-full mx-auto">
        <div className="animate-pulse flex space-x-4">
          <div className="rounded-full bg-neutral-600 h-10 w-10"></div>
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-neutral-600 rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-neutral-600 rounded col-span-2"></div>
                <div className="h-2 bg-neutral-600 rounded col-span-1"></div>
              </div>
              <div className="h-2 bg-neutral-600 rounded"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center">
        <div className="w-full h-full flex items-center justify-center mt-10">
          <motion.div
            className="w-32 h-32 border-4 border-neutral-900 bg-black rounded-full"
            style={{ borderTopColor: '#333' }}
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
          ></motion.div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-16 w-16 text-neutral-800 absolute"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
            />
          </svg>
        </div>
        <p className="mt-5 font-semibold">Approving</p>
      </div>
      <section className="container mx-auto">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <motion.div
          // transition={spring}
          initial={{ y: 40, opacity: 0, scale: 1 }}
          animate={{ y: 0, opacity: 1, scale: 1 }}
          transition={{ duration: 1 }}
          // animate={{ scale: 1.6 }}
          className="w-56 h-56"
          /*animate={{
            x: 0,
            y: -150,
            scale: 1,
            rotate: 0,
          }}*/
        >
          <img
            className="w-56 h-56"
            src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${window.location.href}`}
          />
        </motion.div>

        {/*<motion.div
          className="bg-white w-56 h-56"
          animate={{
            x: 0,
            backgroundColor: '#fff',
            boxShadow: '10px 10px 0 rgba(0, 0, 0, 0.2)',
            position: 'fixed',
            transitionEnd: {
              display: 'none',
            },
          }}
        />*/}
      </section>
      <button className="" onClick={handleOpenModal}>
        Trigger Modal
      </button>
      <div className="p-10">
        <ButtonAnimated text="2" />
      </div>
      <ReactModal
        className="bg-white max-w-5xl mx-auto p-7 rounded-md"
        overlayClassName="bg-black/90 fixed top-0 left-0 bottom-0 right-0 p-5"
        isOpen={showModal}
        contentLabel="Minimal Modal Example"
      >
        <button className="fixed right-7 top-7" onClick={handleCloseModal}>
          <XMarkIcon className="h-8 w-8 text-gray-500" />
        </button>

        <div className="flex w-full flex-row justify-center mb-5">
          <h1 className="text-slate-700 font-semibold text-lg">Thank you</h1>
        </div>
        <img
          alt="Voucher"
          src="https://payments-webapp-assets-stage.s3.us-west-2.amazonaws.com/icon-20-off.png"
          className="mr-2 rounded-md border border-slate-200 p-2 mb-5 shadow"
        />

        <h1 className="text-slate-700">
          Here is a 20% OFF voucher for your next puchase
        </h1>

        <div className="flex w-full flex-row justify-center mt-7">
          <button className="w-full text-slate-700 font-semibold py-3 px-4 rounded border-slate-300 border">
            Redeem
          </button>
        </div>
      </ReactModal>
      <ApprovalPending checkoutSession={checkoutSession} />
      <Approved shouldCapture={true} checkoutSession={checkoutSession} />
      <Captured checkoutSession={checkoutSession} />
      <br />
      <div className="container mx-auto mb-10">
        <h2 className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
          Processing:
        </h2>
        <ul className="max-w-md space-y-2 text-gray-500 list-inside dark:text-gray-400">
          <li className="flex items-center">
            <svg
              aria-hidden="true"
              className="w-5 h-5 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path>
            </svg>
            Approved
          </li>

          <li className="flex items-center">
            <div role="status">
              <svg
                aria-hidden="true"
                className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
            Captured
          </li>
        </ul>
      </div>
    </>
  )
}
