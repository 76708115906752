import { useEffect, useCallback } from 'react'
import { motion } from 'framer-motion'
import { DotAnimation } from '../../../components'

import { CheckoutSession, PaymentStatus } from '../../../types'
import { API_KEY } from '../../../constants'

interface Props {
  shouldCapture: boolean
  checkoutSession: CheckoutSession
}

export default function Approved({ checkoutSession, shouldCapture }: Props) {
  const capturePayment = useCallback(async () => {
    try {
      const res = await fetch(
        `https://payments-api-c560.onrender.com/api/v1/payment_requests/${checkoutSession?.id}/capture`,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'X-API-KEY': API_KEY,
          },
        },
      )

      if (!res.ok) throw new Error('error capturing payment')
    } catch (err) {
      // alert(err)
      console.error(err)
    }
  }, [checkoutSession?.id])

  useEffect(() => {
    if (shouldCapture && checkoutSession.status === PaymentStatus.APPROVED) {
      capturePayment()
    }
  }, [])

  return (
    <div className="flex flex-col justify-center items-center min-h-screen">
      <div className="flex flex-col justify-center items-center">
        {/*<div className="shimmer-container-loading">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-20 h-20 text-neutral-800 "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
            />
          </svg>
  </div>*/}
        <DotAnimation text="Finalizing.." />

        {/*<Lottie
          options={defaultOptions}
          height={'100vh'}
          width={'100vw'}
          isStopped={false}
          isPaused={false}
/>*/}
        {/*<p className="mt-12 font-semibold">Approving</p>*/}
      </div>
      {/* <DotAnimation />*/}
      <p>{/*formatAddress(payment?.transactionHash ?? '') FIXMR*/}</p>
    </div>
  )
}
