import React, { useState } from 'react'
import { BackspaceIcon, ArrowLeftIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { motion, Variants } from 'framer-motion'

const decimals = 2

export type Digits = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

const buttonClassNames = classNames(
  'flex',
  'my-2',
  //'md:m-2',
  'select-none',
  'justify-center',
  'items-center',
  'text-3xl',
  'p-5',
  'w-full',
  'h-14',
  //'border-neutral-400',
  'shadow',
  //'md:h-14',
  'bg-neutral-950',
  'font-medium',
  'dark:bg-black',
  'text-white',
  'dark:text-neutral-300',
  'rounded-md',
  'dark:border-neutral-900',
  'border-0',
  'tap:bg-gray-300',
)

interface NumPadInputButton {
  className?: string
  input: Digits | '.'
  onInput(key: Digits | '.'): void
}

const NumPadButton = ({
  className = '',
  input,
  onInput,
}: NumPadInputButton) => {
  //const onClick = React.useCallback(() => onInput(input), [onInput, input])

  const [isClicked, setIsClicked] = useState(false)

  const handleClick = React.useCallback(() => {
    onInput(input)
    setIsClicked(true)
    setTimeout(() => setIsClicked(false), 150)
  }, [onInput, input])

  const variants: Variants = {
    clicked: { scale: 1.4 },
    unclicked: { scale: 1 },
  }

  return (
    <motion.button
      className={classNames(buttonClassNames, className)}
      type="button"
      onClick={handleClick}
      variants={variants}
      animate={isClicked ? 'clicked' : 'unclicked'}
    >
      {input}
    </motion.button>
  )
}

interface BackSpaceButtonProps {
  className: string
  onClick: () => void
}

const BackSpaceButton = ({ className, onClick }: BackSpaceButtonProps) => {
  const [isClicked, setIsClicked] = useState(false)

  const handleClick = React.useCallback(() => {
    onClick()
    setIsClicked(true)
    setTimeout(() => setIsClicked(false), 150)
  }, [])

  const variants: Variants = {
    clicked: { scale: 1.4 },
    unclicked: { scale: 1 },
  }

  return (
    <motion.button
      className={classNames(buttonClassNames, className)}
      type="button"
      onClick={handleClick}
      variants={variants}
      animate={isClicked ? 'clicked' : 'unclicked'}
    >
      <ArrowLeftIcon className="h-8 w-8" aria-hidden="true" />
    </motion.button>
  )
}

interface Props {
  onInvalid: () => void
  setAmount: (value: string | undefined) => void
}

export default function KeyPad({ setAmount, onInvalid }: Props) {
  const [value, setValue] = React.useState('0')

  const regExp = React.useMemo(
    () => new RegExp('^\\d*([.,]\\d{0,' + decimals + '})?$'),
    [
      /*decimals*/
    ],
  )

  const onInput = React.useCallback(
    (key: Digits | '.') =>
      setValue((value) => {
        if (Number(value) >= 99999) {
          return value
        }
        let newValue = (value + key).trim().replace(/^0{2,}/, '0')

        if (newValue) {
          newValue = /^[.,]/.test(newValue)
            ? `0${newValue}`
            : newValue.replace(/^0+(\d)/, '$1')

          if (regExp.test(newValue)) return newValue
        }
        return value
      }),
    [regExp],
  )

  const onBackspace = React.useCallback(
    () =>
      setValue((value) => {
        if (value === '0') {
          onInvalid()
        }
        return value.length ? value.slice(0, -1) || '0' : value
      }),
    [],
  )

  React.useEffect(() => setAmount(value), [setAmount, value])

  return (
    <div className="text-center select-none">
      <div className="flex justify-center select-none">
        <NumPadButton
          className="mr-2 select-none"
          input={1}
          onInput={onInput}
        />
        <NumPadButton
          className="mx-2 select-none"
          input={2}
          onInput={onInput}
        />
        <NumPadButton
          className="ml-2 select-none"
          input={3}
          onInput={onInput}
        />
      </div>
      <div className="flex justify-center select-none">
        <NumPadButton
          className="mr-2 select-none"
          input={4}
          onInput={onInput}
        />
        <NumPadButton
          className="mx-2 select-none"
          input={5}
          onInput={onInput}
        />
        <NumPadButton
          className="ml-2 select-none"
          input={6}
          onInput={onInput}
        />
      </div>

      <div className="flex justify-center select-none">
        <NumPadButton
          className="mr-2 select-none"
          input={7}
          onInput={onInput}
        />
        <NumPadButton
          className="mx-2 select-none"
          input={8}
          onInput={onInput}
        />
        <NumPadButton
          className="ml-2 select-none"
          input={9}
          onInput={onInput}
        />
      </div>

      <div className="flex justify-center select-none">
        <NumPadButton
          className="mr-2 select-none"
          input={'.'}
          onInput={onInput}
        />
        <NumPadButton
          className="mx-2 select-none"
          input={0}
          onInput={onInput}
        />
        <BackSpaceButton className="ml-2 select-none" onClick={onBackspace} />
      </div>
    </div>
  )
}
