import {
  PaymentStatusBadge,
  DesktopNavBar,
  NetworkIcon,
  BottomNavigation,
  Switch,
} from '../../components'

export default function Settings() {
  return (
    <div className="max-w-screen-2xl mx-auto px-5">
      <h3 className="mb-10 mt-5">Settings</h3>

      <div className="flex justify-between">
        <p>Auto capture</p>
        <Switch />
      </div>

      <DesktopNavBar />
      <BottomNavigation />
    </div>
  )
}
