import { useState, useEffect, useMemo } from 'react'
import { Routes, Route, Outlet, Link, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { parseISO, format } from 'date-fns'
import { ClockIcon } from '@heroicons/react/20/solid'
import { groupDataByDay, chainName, delay } from '../../utils'

import { useInterval, useDevice } from '../../hooks'
import { PaymentRequest, PaymentStatus, ChainId } from '../../types'

/*
import {
  StatusIcon,
  NetworkIcon,
  Pagination,
  Chart24Hour,
  PieChartPaymentRequests,
  BarChartHorizontal,
  DateDurationSelect,
} from '../components'
*/

import {
  PaymentStatusBadge,
  DesktopNavBar,
  NetworkIcon,
  BottomNavigation,
} from '../../components'

import TabelList from './TableList'
import MobileList from './MobileList'
import { API_KEY } from '../../constants'
export enum Status {
  Loading,
  Ready,
  Error,
}

export default function Avtivity() {
  const [status, setStatus] = useState(Status.Loading)

  const [paymentRequests, setPaymentRequests] = useState<PaymentRequest[]>([])

  const { phone } = useDevice()

  let navigate = useNavigate()

  async function fetchPaymentRequests() {
    try {
      const res = await fetch(
        'https://payments-api-c560.onrender.com/api/v1/payment_requests',
        {
          method: 'GET',
          headers: {
            accept: 'application/json',
            'X-API-KEY': API_KEY,
            'Content-Type': 'application/json',
          },
        },
      )
      const { data } = await res.json()

      // await delay()

      setPaymentRequests(data)

      setStatus(Status.Ready)
    } catch (err) {
      // setStatus
      console.error(err)
    }
  }

  useEffect(() => {
    fetchPaymentRequests()
  }, [])

  // groupDataByDay
  const requestsGroupedByDay = useMemo(() => {
    return groupDataByDay(
      paymentRequests.sort((a, b) => {
        if (a.updated_at > b.updated_at) return -1
        if (a.updated_at < b.updated_at) return 1
        else return 0
      }),
    )
  }, [paymentRequests])

  useInterval(
    async () => {
      try {
        await fetchPaymentRequests()
      } catch (err) {
        console.error(err)
      }
    },
    // Delay in milliseconds or null to stop it
    2000,
  )

  return (
    <div className="max-w-screen-2xl mx-auto px-5">
      <h3 className="text-xl mb-5 mt-5 ms-12">Activity</h3>

      {/*<section className="mt-5 bg-white p-8 rounded-lg border border-gray-300">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-8">
          <div className="col-span-12 md:col-span-7 p-4 mb-5">
            <h3 className="mb-5">24 Hour</h3>
            <Chart24Hour />
          </div>

          <div className="col-span-12 md:col-span-1"></div>
          <div className="flex flex-col col-span-12 md:col-span-4">
            <div className="flex justify-end mt-5">
              <DateDurationSelect />
            </div>

            <div className="mt-8">
              <BarChartHorizontal />
            </div>
          </div>
        </div>
  </section>*/}

      {phone ? (
        <MobileList data={requestsGroupedByDay} status={status} />
      ) : (
        <TabelList data={requestsGroupedByDay} status={status} />
      )}

      <DesktopNavBar />
      <BottomNavigation />
    </div>
  )
}
