import { useEffect, useState, useMemo } from 'react'

import { ethers, BigNumber, constants, utils } from 'ethers'
import { useInterval } from '../../hooks'
import classNames from 'classnames'
import invariant from 'tiny-invariant'
import { TicketIcon } from '@heroicons/react/24/outline'
import * as featureFlag from '../../featureFlags'

import { formatCurrencyAmount, fetchWithTimeout } from '../../utils'

import { Approved, ApprovalPending } from './Loading'

import CouponsList from './CouponsList'

import ConnectChainButton from './ConnectChainButton'
import NavBar from './NavBar'
import BalanceWarning from './BalanceWarning'
import Rewards from './Rewards'
import Captured from './Captured'
import NativeBalanceWarning from './NativeBalanceWarning'

import {
  ButtonPill,
  LoaderSpinnerButton,
  FadeInDelayed,
} from '../../components'

import { useQuery } from '../../hooks'
import {
  PaymentStatus,
  Network,
  CheckoutSession,
  chainIdHex,
  ChainInfo,
  networkToChainId,
} from '../../types'

import { useAccount, useSigner, useNetwork, useSwitchNetwork } from 'wagmi'

export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(120).div(100)
}

export default function Pay() {
  const query = useQuery()
  const id = query.get('id')
  const network = query.get('network') as Network
  const wallet = query.get('wallet')

  /*
   * Wallet connect
   */
  const { data: signerWalletConnect } = useSigner()

  const { chain } = useNetwork()
  const { switchNetwork } = useSwitchNetwork()

  const { address, isConnected } = useAccount()

  /*
   * State
   */
  const [chainId, setChainId] = useState<number>()
  const [account, setAccount] = useState('')

  const [showRewards, setShowRewards] = useState(false)
  const [checkoutSession, setCheckoutSession] = useState<CheckoutSession>()

  const [isPageHidden, setIsPageHidden] = useState(document.hidden)
  const [isProcessing, setIsProcessing] = useState(false)

  const [isCheckoutSessionPending, setIsCheckoutSessionPending] = useState(
    false,
  )

  const hasWalletInfoLoaded = useMemo(() => {
    return !!checkoutSession?.wallet?.address
    // &&
    // !!checkoutSession?.wallet?.native_balance?.value
  }, [
    checkoutSession?.wallet?.address,
    ///    checkoutSession?.wallet?.native_balance?.value,
  ])

  function setPageHidden() {
    setIsPageHidden(document.hidden)
  }

  useEffect(() => {
    if (document.addEventListener)
      document.addEventListener('visibilitychange', () => setPageHidden)

    return () => {
      document.removeEventListener('visibilitychange', () => setPageHidden)
    }
  }, [])

  const USDC = useMemo(() => {
    const paymentMethod = checkoutSession?.networks?.find(
      (method) => method.chain === network,
    )
    const token = paymentMethod?.tokens?.[0]

    return token
  }, [network, checkoutSession])

  const usdcBalance = useMemo(() => {
    const usdcToken = checkoutSession?.wallet?.tokens?.find(
      (token) => token.symbol === 'USDC',
    )
    const balance = usdcToken?.balance?.toString() ?? '0'
    const decimals = USDC?.decimals

    return utils.formatUnits(balance, decimals).toString()
  }, [checkoutSession?.wallet?.tokens, USDC?.decimals])

  /*
  const nativeBalance = useMemo(() => {
    return checkoutSession?.wallet?.native_balance?.value
  }, [checkoutSession?.wallet?.native_balance?.value])
*/

  const hasBalance = useMemo(() => {
    const usdcBalanceNumber = Number(usdcBalance) || 0
    const checkoutTotalValue = Number(checkoutSession?.total?.value) || 0

    return usdcBalanceNumber >= checkoutTotalValue
  }, [usdcBalance, checkoutSession?.total?.value])

  const tokens = useMemo(() => {
    return checkoutSession?.networks?.find((method) => method.chain === network)
      ?.tokens
  }, [checkoutSession?.networks, network])

  async function connectedWalletInfo() {
    try {
      if (isConnected) {
        invariant(address, 'no wallet connect address')
        invariant(signerWalletConnect, 'no signerWalletConnect :(')

        return {
          address,
          chainId: `0x${chain?.id.toString(16)}`,
          signer: signerWalletConnect,
        }
      } else {
        if (!(window as any).ethereum) throw new Error('no window.ethereum')

        const provider = new ethers.providers.Web3Provider(
          (window as any).ethereum,
          'any',
        )

        if (!provider) {
          throw new Error('connect wallet webview - no provider')
        }
        const signer = provider.getSigner()
        if (!signer) throw new Error('no signer :(')

        const accounts = await (window as any).ethereum.request({
          method: 'eth_requestAccounts',
        })
        if (!accounts[0]) throw new Error('connect wallet webview - no account')

        const chainId = (await (window as any).ethereum.request({
          method: 'eth_chainId',
        })) as string

        if (!chainId) throw new Error('no chain_id')

        return {
          address: accounts[0],
          chainId,
          signer,
        }
      }
    } catch (err) {
      console.error(err)
      // throw new Error('getting connected wallet info')
      return {
        address: null,
        chainId: null,
      }
    }
  }

  async function connect() {
    const { address, chainId } = await connectedWalletInfo()

    if (!address || !chainId) {
      console.error('no address or chainID')
      return
    }

    try {
      setChainId(BigNumber.from(chainId).toNumber())

      if (!address) throw new Error('no account')
      setAccount(address)
    } catch (err) {
      console.error(err)
    }
  }

  async function pay() {
    try {
      if (!id) throw new Error('missing id for pay')

      setIsProcessing(true)
      if (!tokens && !tokens!.length) throw new Error('pay: no tokens')

      const USDC = tokens![0]

      invariant(USDC?.approval_target, 'no approvalAddress')
      invariant(USDC?.address, 'no usdcAddress')
      invariant(checkoutSession?.total?.value, 'no payment amount value')

      const { address, chainId, signer } = await connectedWalletInfo()

      if (!signer) throw new Error('no signer')
      if (!address) throw new Error('no address')
      if (!chainId) throw new Error('no chainId')

      const nonce = await signer.getTransactionCount('pending')

      let {
        //  lastBaseFeePerGas,
        maxFeePerGas,
        maxPriorityFeePerGas,
        // gasPrice,
      } = await signer.getFeeData()

      // Polygon use gas station
      if (BigNumber.from(chainId).toNumber() === 137) {
        const response = await fetch('https://gasstation.polygon.technology/v2')

        if (!response.ok) {
          throw new Error(
            `Failed to fetch gas price, status code: ${response.status}`,
          )
        }

        const data = await response.json()

        maxFeePerGas = BigNumber.from((data.fast.maxFee * 10 ** 9).toFixed(0))
        maxPriorityFeePerGas = BigNumber.from(
          (data.fast.maxPriorityFee * 10 ** 9).toFixed(0),
        )
      }

      // const gasPrice = data.fast.maxPriorityFee

      if (!maxFeePerGas) throw new Error('no maxFeePerGas')
      if (!maxPriorityFeePerGas) throw new Error('no maxPriorityFeePerGas')

      const estimateGas = await signer.estimateGas({
        ...USDC.approval_tx,
      })

      const tx = await signer.sendTransaction({
        nonce,
        type: 2,
        chainId: BigNumber.from(chainId).toNumber(),
        ...USDC.approval_tx,
        maxFeePerGas,
        maxPriorityFeePerGas,
        gasLimit: calculateGasMargin(estimateGas),
      })

      setCheckoutSession({
        ...checkoutSession,
        status: PaymentStatus.APPROVAL_PENDING,
      })

      console.log(JSON.stringify(tx, null, 4))

      updateCheckoutSession(id, tx)

      setIsProcessing(false)
    } catch (err) {
      // TODO error handle network requests failures
      if ((err as any)?.code !== 'ACTION_REJECTED') {
        console.error(err)
        alert((err as any).message)
      }
      /*
      setCheckoutSession({
        ...checkoutSession,
        status: PaymentStatus.CREATED,
      })
      */
      // setIsProcessing(false)
    }
  }

  async function updateCheckoutSession(
    id: string,
    tx: ethers.providers.TransactionResponse,
  ) {
    const res = await fetchWithTimeout(
      `https://payments-api-c560.onrender.com/api/v1/checkout_session/${id}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          payment: {
            approve_tx: tx,
          },
        }),
      },
      10000,
    )

    if (!res.ok) throw new Error('checkout session update failed')
  }

  async function fetchCheckoutSession() {
    setIsCheckoutSessionPending(true)
    try {
      if (!id) {
        throw new Error('no id')
      }

      let url = `https://payments-api-c560.onrender.com/api/v1/checkout_session/${id}`

      if (account && chainId) {
        console.log('fetch_checkout_session_wallet')
        url += `?address=${account}&chain_id=${chainId}`
      }

      const res = await fetchWithTimeout(
        url,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        1900, // polling is every 2 sec - keep the timeout less that that
      )

      if (!res.ok) throw new Error('fetching checkout session')

      const data = (await res.json()) as CheckoutSession

      if (!data) {
        throw new Error('no payment found.')
      }

      if (checkoutSession?.status !== data.status) {
        console.log(
          JSON.stringify(
            {
              current: checkoutSession?.status,
              update: data.status,
            },
            null,
            4,
          ),
        )
      }

      /*
       * Ignore stale updates
       */
      if (
        checkoutSession?.status === PaymentStatus.APPROVAL_PENDING &&
        data.status === PaymentStatus.CREATED
      ) {
        setIsCheckoutSessionPending(false)
        return
      }

      if (
        checkoutSession?.status === PaymentStatus.APPROVED &&
        data.status === PaymentStatus.CREATED
      ) {
        setIsCheckoutSessionPending(false)
        return
      }

      if (
        checkoutSession?.status === PaymentStatus.APPROVED &&
        data.status === PaymentStatus.CREATED
      ) {
        setIsCheckoutSessionPending(false)
        return
      }

      if (
        checkoutSession?.status === PaymentStatus.CAPTURE_PENDING &&
        data.status === PaymentStatus.APPROVED
      ) {
        setIsCheckoutSessionPending(false)
        return
      }

      setCheckoutSession(data)
      setIsCheckoutSessionPending(false)
    } catch (err) {
      console.error(err)
      setIsCheckoutSessionPending(false)
    }
  }

  async function switchChains(network: Network) {
    console.log('_____ switchChains _____')

    if (isConnected && address) {
      switchNetwork?.(networkToChainId[network])
      setChainId(networkToChainId[network])
    } else {
      if (!(window as any).ethereum) {
        throw new Error('[PAY:switchChains] no window.etherum')
      }

      try {
        await (window as any).ethereum.request({
          method: `wallet_switchEthereumChain`,
          params: [
            {
              chainId: chainIdHex[network],
            },
          ],
        })
      } catch (err) {
        const res = await fetch(
          `https://payments-api-c560.onrender.com/api/v1/chain/${networkToChainId[network]}`,
        )
        const data = (await res.json()) as ChainInfo

        // alert((err as any).message)
        /*
        await (window as any).ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: data.chain_id,
              rpcUrls: data.rpc_urls,
              chainName: data.chain_name,
              nativeCurrency: {
                name: data.native_currency.name,
                symbol: data.native_currency.symbol,
                decimals: data.native_currency.decimals,
              },
              blockExplorerUrls: data.block_explorer_urls,
            },
          ],
        })
        */
      }

      const chainId = await (window as any).ethereum.request({
        method: 'eth_chainId',
      })
      setChainId(BigNumber.from(chainId).toNumber())
    }
  }

  function reloadPage() {
    // window.location.reload()
  }

  useEffect(() => {
    if ((window as any).ethereum) {
      ;(window as any).ethereum.on('chainChanged', reloadPage)

      return () => {
        ;(window as any).ethereum.removeListener('chainChanged', reloadPage)
      }
    }
  }, [(window as any).ethereum])

  useEffect(() => {
    if (id) {
      fetchCheckoutSession()
    }
  }, [])

  useEffect(() => {
    if (checkoutSession) {
      connect()
    }
  }, [checkoutSession?.id])

  /*
  useInterval(
    async () => {
      connect()
    },
    !chainId ? 1000 : null,
  )
*/

  useInterval(
    async () => {
      console.log(
        JSON.stringify({
          account,
          chainId,
          id,
          status: checkoutSession?.status,
          network,
        }),
      )

      try {
        if (!id) {
          throw new Error('no payment id')
        }

        if (isCheckoutSessionPending) {
          throw new Error('skipping as isPending...')
        }

        await fetchCheckoutSession()
      } catch (err) {
        console.error(err)
      }
    },
    // Delay in milliseconds or null to stop it
    !isPageHidden && checkoutSession?.status !== PaymentStatus.CAPTURED
      ? 2000
      : null,
  )

  if (!id) return null
  /*
  if (status === Status.Loading) {
    return <LoadingSpinner />
  }
*/
  if (checkoutSession?.status === PaymentStatus.APPROVAL_PENDING) {
    return <ApprovalPending checkoutSession={checkoutSession} />
  }

  if (
    checkoutSession?.status === PaymentStatus.APPROVED ||
    checkoutSession?.status === PaymentStatus.CAPTURE_PENDING
  ) {
    return <Approved shouldCapture={true} checkoutSession={checkoutSession} />
  }

  if (checkoutSession?.status === PaymentStatus.CAPTURED) {
    return <Captured checkoutSession={checkoutSession} />
  }

  if (!checkoutSession) {
    return (
      <div className="max-w-sm mx-auto h-screen flex flex-col justify-center items-center min-h-screen p-5">
        <div className="flex flex-col">
          <FadeInDelayed delay={1000}>
            <p>Expired</p>
          </FadeInDelayed>
        </div>
      </div>
    )
  }

  if (!chainId) {
    return (
      <div className="max-w-sm mx-auto h-screen flex flex-col justify-center items-center min-h-screen p-5">
        <div className="flex flex-col">
          <FadeInDelayed delay={1000}>
            <p>No connected wallet found</p>
          </FadeInDelayed>
        </div>
      </div>
    )
  }

  if (
    network === Network.POLYGON &&
    chainId !== networkToChainId[Network.POLYGON]
  ) {
    return (
      <ConnectChainButton
        switchChains={switchChains}
        network={Network.POLYGON}
      />
    )
  }

  if (
    network === Network.POLYGON_MUMBAI &&
    chainId !== networkToChainId[Network.POLYGON_MUMBAI]
  ) {
    return (
      <ConnectChainButton
        switchChains={switchChains}
        network={Network.POLYGON_MUMBAI}
      />
    )
  }

  if (
    network === Network.GOERLI &&
    chainId !== networkToChainId[Network.GOERLI]
  ) {
    return (
      <ConnectChainButton
        switchChains={switchChains}
        network={Network.GOERLI}
      />
    )
  }

  if (
    network === Network.BASE_GOERLI &&
    chainId !== networkToChainId[Network.BASE_GOERLI]
  ) {
    return (
      <ConnectChainButton
        switchChains={switchChains}
        network={Network.BASE_GOERLI}
      />
    )
  }

  if (
    network === Network.OPTIMISM_GOERLI &&
    chainId !== networkToChainId[Network.OPTIMISM_GOERLI]
  ) {
    return (
      <ConnectChainButton
        switchChains={switchChains}
        network={Network.OPTIMISM_GOERLI}
      />
    )
  }

  if (showRewards) {
    return (
      <Rewards
        paymentID={checkoutSession?.id}
        rewards={checkoutSession?.rewards}
        points={checkoutSession?.wallet?.points}
        onClose={() => setShowRewards(false)}
      />
    )
  }

  return (
    <div className="p-4 md:max-w-2xl md:mx-auto flex flex-col justify-between pb-40">
      <NavBar
        checkoutSession={checkoutSession}
        wallet={wallet}
        chainId={chainId}
        account={account}
        hasWalletInfoLoaded={hasWalletInfoLoaded}
      />
      {hasWalletInfoLoaded &&
      checkoutSession?.wallet?.native_balance?.value === 0 ? (
        <section className="mt-5">
          <NativeBalanceWarning
            networkName={network}
            symbol={checkoutSession?.wallet?.native_balance?.symbol}
          />
        </section>
      ) : (
        hasWalletInfoLoaded &&
        checkoutSession?.wallet?.tokens &&
        !hasBalance && (
          <section className="mt-3">
            <BalanceWarning
              network={network}
              checkoutSession={checkoutSession}
            />
          </section>
        )
      )}

      <section className="mt-10">
        <CouponsList
          checkoutSession={checkoutSession}
          hasWalletInfoLoaded={hasWalletInfoLoaded}
        />
      </section>
      <section className="flex justify-between mt-10">
        <div className="flex flex-col">
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 mr-2 text-greeeen"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
              />
            </svg>
            <h1 className="font-semibold">Rewards</h1>
          </div>

          {hasWalletInfoLoaded ? (
            <>
              <p className="text-xs text-neutral-400 mb-2 mt-2">
                Earn 1 Point for every $1 spent.
              </p>{' '}
              <p className="text-white">
                {' '}
                {Math.floor(
                  (checkoutSession?.wallet?.points?.value ?? 0) / 10 ** 6,
                )}{' '}
                Points
              </p>
            </>
          ) : (
            <div className="flex flex-col">
              <div className="animate-pulse h-4 w-28 bg-neutral-700 rounded mb-2 mt-2"></div>
              <div className="animate-pulse h-4 w-10 bg-neutral-700 rounded"></div>
            </div>
          )}
        </div>

        <div className="flex items-center">
          {hasWalletInfoLoaded ? (
            <ButtonPill
              onClick={() => {
                setShowRewards(true)
              }}
            >
              Rewards
            </ButtonPill>
          ) : (
            <div className="animate-pulse h-10 w-28 bg-neutral-700 rounded-3xl "></div>
          )}
        </div>
      </section>
      <section className="mt-10 flex flex-col justify-center">
        <div className="flex flex-col">
          <div className="flex flex-col w-full">
            <div className="flex justify-between mb-5">
              <p>Pay {checkoutSession?.soft_descriptor}</p>
            </div>

            {checkoutSession?.display_items?.map((item, idx) => (
              <div
                key={idx}
                className="flex justify-between text-neutral-400 fade-in mb-1"
              >
                <p>{item.label}</p>
                <p>{formatCurrencyAmount(item.value)}</p>
              </div>
            ))}

            <div className="flex justify-between mt-2">
              <p>Total</p>
              <div className="flex justify-center items-center">
                <p className="flex">
                  {Number(checkoutSession?.total?.value ?? '0').toFixed(2)}{' '}
                  {USDC?.symbol}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {hasWalletInfoLoaded ? (
        <div className="fixed bg-black p-4 bottom-0  left-0 right-0">
          <div className="md:max-w-2xl md:mx-auto">
            <button
              disabled={!hasBalance}
              onClick={pay}
              className={classNames(
                'font-medium',
                'min-w-full',
                'mx-auto',
                'flex',
                'items-center',
                'justify-center',
                'text-gray-600',
                'rounded-md',
                'space-x-2',
                'transition',
                'duration-150',
                'shadow',
                'bg-white',
                'dark:bg-white',
                'text-gray-500',
                'dark:text-black',
                'h-14',
                'text-base',
                'border-neutral-800',
                'border',
                'disabled:opacity-25',
              )}
            >
              {isProcessing ? (
                <>
                  <LoaderSpinnerButton />
                  Waiting..
                </>
              ) : (
                'Pay'
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="fixed p-4 bottom-0 left-0 right-0">
          <div className="md:max-w-2xl md:mx-auto">
            <div className="w-full h-14 animate-pulse bg-neutral-700 rounded-md" />
          </div>
        </div>
      )}
    </div>
  )
}
