import { useState, useEffect } from 'react'
import { DotAnimation, Loading } from '../../components'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { XMarkIcon, QrCodeIcon } from '@heroicons/react/20/solid'
import Framebus from 'framebus'
import { motion } from 'framer-motion'
import Completed from './Completed'
import { useQuery } from '../../hooks'

import { BsQrCodeScan } from 'react-icons/bs'

import {
  QRCode,
  Approved,
  ApprovalPending,
  ButtonBounce,
} from '../../components'

import { PaymentStatus, CheckoutSession, Network, Source } from '../../types'
import { useInterval, useDevice, useSalesTax } from '../../hooks'

import { formatCurrencyAmount, encodeQueryData } from '../../utils'

const bus = new Framebus()

enum Status {
  Loading,
  Ready,
  Processing,
}

interface QRCodeURLParams {
  id?: string | null
  source?: Source
}
function buildQRCodeURL({ id, source }: QRCodeURLParams) {
  let url = `${window.location.origin}/checkout${encodeQueryData({
    id,
    source,
  })}`

  if (source === Source.APP) {
    url = `walletapp://checkout?id=${id}`
  }

  return url
}

export default function Scan() {
  const navigate = useNavigate()

  const { phone } = useDevice()
  const { zipCode, salesTaxRate } = useSalesTax()

  let query = useQuery()
  const id = query.get('id')
  const source = query.get('source') as Source

  const [checkoutSession, setCheckoutSession] = useState<CheckoutSession>()

  const [status, setStatus] = useState(Status.Loading)
  const [hasError, setHasError] = useState(false)

  async function fetchCheckoutSession() {
    if (!id) return

    const res = await fetch(
      `https://payments-api-c560.onrender.com/api/v1/checkout_session/${id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (!res.ok) {
      setHasError(true)
      return
    }

    const checkoutSession = await res.json()

    setCheckoutSession(checkoutSession)

    setStatus(Status.Ready)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchCheckoutSession()
  }, [])

  useInterval(
    async () => {
      try {
        const res = await fetch(
          `https://payments-api-c560.onrender.com/api/v1/checkout_session/${id}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        if (!res.ok) throw new Error('failed to fetch checkout session')

        const checkoutSession = await res.json()

        if (!checkoutSession) {
          throw new Error('no payment found..')
        }

        setHasError(false)

        if (checkoutSession?.status === PaymentStatus.APPROVED) {
          setTimeout(() => {
            bus.emit(`onApprove`, {
              id: checkoutSession.id,
            })
          }, 1000)
        }
        setCheckoutSession(checkoutSession)
        console.log(checkoutSession.status)
      } catch (err) {
        console.error(err)
      }
    },
    checkoutSession?.status === PaymentStatus.CAPTURED ? null : 1000,
  )

  if (hasError) {
    return (
      <main className="max-w-sm min-h-screen mx-auto flex flex-col justify-start md:justify-center items-center px-10 pl-10 py-4">
        <div className="flex flex-col justify-center items-center">
          <svg
            className="w-12 h-12 mb-5 text-neutral-800"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>

          <h3>Payment expired</h3>
        </div>
      </main>
    )
  }

  if (status === Status.Loading) {
    //return <LoadingScreen />
  }

  if (checkoutSession?.status === PaymentStatus.APPROVAL_PENDING) {
    return <ApprovalPending checkoutSession={checkoutSession} />
  }

  if (
    checkoutSession?.status === PaymentStatus.APPROVED ||
    checkoutSession?.status === PaymentStatus.CAPTURE_PENDING
  ) {
    return <Approved shouldCapture={false} checkoutSession={checkoutSession} />
  }

  if (checkoutSession?.status === PaymentStatus.CAPTURED) {
    return <Completed checkoutSession={checkoutSession} source={source} />
  }

  return (
    <main className="fixed left-0 top-0 right-0 bottom-0 h-screen  flex flex-col justify-start md:justify-center items-center">
      {source === Source.TERMINAL && !phone && (
        <button
          onClick={() => {
            navigate(`/`)
          }}
          className="fixed left-3 top-3"
        >
          <XMarkIcon className="h-10 w-10 text-gray-300" />
        </button>
      )}

      <div className="flex flex-col justify-center fade-in container mx-auto  md:mt-0 md:flex-row md:flex-row-reverse px-5">
        <section className="flex flex-col justify-center items-center md:justify-between md:items-start fade-in md:pl-5 md:w-1/4">
          <div className="flex flex-col w-full">
            {status === Status.Loading && (
              <>
                {/*<div
                  style={{
                    width: '150px',
                    height: '30px',
                    marginBottom: '20px',
                  }}
                  className="shimmer shimmer-animate"
                ></div>
                <div
                  style={{
                    width: '100px',
                    height: '20px',
                    marginBottom: '20px',
                  }}
                  className="shimmer shimmer-animate"
                ></div>
                <div
                  style={{
                    width: '100px',
                    height: '20px',
                    marginBottom: '20px',
                  }}
                  className="shimmer shimmer-animate"
                ></div>
                <div
                  style={{
                    width: '100px',
                    height: '20px',
                    marginBottom: '20px',
                  }}
                  className="shimmer shimmer-animate"
                ></div>*/}
              </>
            )}

            {status === Status.Ready && (
              <>
                {source === Source.TERMINAL && (
                  <div className="flex flex-col justify-center">
                    <motion.div
                      initial={{ y: 0, opacity: 0, scale: 1 }}
                      animate={{ y: 0, opacity: 1, scale: 1 }}
                      transition={{ duration: 0.9 }}
                    >
                      <div className="flex flex-row justify-between items-center mt-3 md:mt-0 mb-4">
                        <p className="text-xl font-medium text-white">
                          Pay {checkoutSession?.soft_descriptor}
                        </p>

                        <ButtonBounce
                          onClick={(e: any) => {
                            navigate(`/`)
                          }}
                          className="visible md:invisible"
                        >
                          <XMarkIcon className="h-10 w-10 text-gray-300" />
                        </ButtonBounce>
                      </div>

                      <div className="text-sm flex flex-col w-full mb-8 mt-5 md:mt-5 md:max-w-full mx-auto">
                        {checkoutSession?.display_items?.map((item) => {
                          if (item.label.toLowerCase().includes('sales tax')) {
                            return (
                              <div
                                key={`${item.label}:${item.value}`}
                                className="flex w-full flex-row justify-between mb-2"
                              >
                                <p className="text-neutral-400">
                                  {item.label}{' '}
                                  <span className="text-xs text-neutral-400 ml-5">
                                    {zipCode?.length === 5 &&
                                      `${Number(salesTaxRate * 100).toFixed(
                                        2,
                                      )} %`}
                                  </span>
                                </p>

                                <p className="text-neutral-400 flex items-center">
                                  {formatCurrencyAmount(item.value)}{' '}
                                </p>
                              </div>
                            )
                          }
                          return (
                            <div
                              key={`${item.label}:${item.value}`}
                              className="flex w-full flex-row justify-between mb-2"
                            >
                              <p className="text-neutral-400">{item.label}</p>

                              <p className="text-neutral-400 flex items-center">
                                {formatCurrencyAmount(item.value)}{' '}
                              </p>
                            </div>
                          )
                        })}
                        <div className="flex justify-between items-center w-full text-white">
                          <p className="text-lg font-semibold">Total</p>
                          <p className="text-lg font-semibold">
                            {formatCurrencyAmount(
                              checkoutSession?.total?.value,
                            )}
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                )}
              </>
            )}
          </div>
        </section>

        <section className="flex flex-col justify-center items-center fade-in md:w-1/2">
          {status === Status.Loading ? (
            <>
              {' '}
              {/*<div
                style={{
                  width: '300px',
                  height: '300px',
                  marginBottom: '20px',
                }}
                className="shimmer shimmer-animate"
              ></div>
              <div
                style={{
                  width: '100px',
                  height: '30px',
                  marginBottom: '20px',
                }}
                className="shimmer shimmer-animate"
              ></div>*/}
            </>
          ) : (
            <>
              {checkoutSession?.status === PaymentStatus.CREATED ? (
                <motion.div
                // initial={{ y: 0, opacity: 0, scale: 1 }}
                // animate={{ y: 0, opacity: 1, scale: 1 }}
                // transition={{ duration: 0.5, delay: 0.3 }}
                >
                  <a href={`walletapp://checkout?id=${id}`}>
                    <QRCode
                      //   size={200}
                      network={Network.POLYGON}
                      url={buildQRCodeURL({
                        id,
                        source,
                      })}
                    />
                  </a>
                </motion.div>
              ) : (
                <div className="flex justify-center items-center mt-10">
                  <DotAnimation text="Waiting.." />
                </div>
              )}

              {checkoutSession?.status === PaymentStatus.CREATED && (
                <div className="w-full flex flex-row justify-center items-center mb-7 mt-10">
                  <h3 className="w-5 h-5 text-2xl text-greeeen">
                    <BsQrCodeScan />
                  </h3>

                  {/* <QrCodeIcon className="w-5 h-5 text-greeeen" />*/}
                  <p className="text-sm font-semibold text-gray-300 ml-6">
                    Scan to pay
                  </p>
                </div>
              )}

              {source === Source.TERMINAL &&
                checkoutSession?.status === PaymentStatus.CREATED && (
                  <div className="flex flex-col items-center">
                    <Link
                      //  target="_blank"
                      className="mt-2 text-sm font-semibold  text-neutral-500 hover:underline rounded-full bg-neutral-900 px-4 py-2"
                      to={buildQRCodeURL({
                        id,
                        source,
                      })}
                      rel="noreferrer"
                    >
                      {phone ? 'Open QR code' : 'Desktop'}
                    </Link>
                  </div>
                )}
            </>
          )}
        </section>
      </div>
    </main>
  )
}
