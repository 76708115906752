import { motion } from 'framer-motion'
import { ReactNode } from 'react'

interface Props {
  trigger: boolean
  children: ReactNode
}

export default function ShakeAnimation({ children, trigger }: Props) {
  const shakeVariants = {
    shaking: {
      x: [0, -10, 10, -10, 10, -3, 3, -1, 1, 0],
      transition: { duration: 0.3 },
    },
  }

  return (
    <motion.div
      animate={trigger ? 'shaking' : undefined}
      variants={shakeVariants}
    >
      {children}
    </motion.div>
  )
}
