import React, { ReactNode } from 'react'
import classNames from 'classnames'

interface Props {
  children: ReactNode
  fullWidth?: boolean
  onClick?: (() => void) | (() => Promise<void>)
  disabled?: boolean
  tint?: boolean
}

export default function ButtonPill({
  onClick,
  children,
  disabled,
  fullWidth = false,
  tint = false,
}: Props) {
  const buttonClasses = classNames(
    'flex justify-center font-semibold items-center py-2 px-3 bg-neutral-600 text-white rounded-3xl',
    {
      'w-full': fullWidth,
      'text-greeeen': tint,
    },
  )

  return (
    <button disabled={disabled} onClick={onClick} className={buttonClasses}>
      {children}
    </button>
  )
}
