import React, { FC, ReactNode, useState, createContext } from 'react'

import { TaxInfo } from '../types'

export interface TaxContextState {
  salesTaxRate: number
  zipCode: string
  updateZipCode(zipcode: string): void
}

export const TaxContext = createContext<TaxContextState>({} as TaxContextState)

export interface Props {
  children: ReactNode
}

export const TaxProvider: FC<Props> = ({ children }) => {
  const [salesTaxRate, setSalesTaxRate] = useState<number>(0)
  const [zipCode, setZipCode] = useState('')

  async function updateZipCode(zipCode: string) {
    setZipCode(zipCode)
    const res = await fetch(
      `https://payments-api-c560.onrender.com/api/v1/tax/us/${zipCode}`,
    )
    const data = (await res.json()) as TaxInfo

    console.log(data)

    setSalesTaxRate(data.estimated_combined_rate)
  }

  return (
    <TaxContext.Provider value={{ salesTaxRate, zipCode, updateZipCode }}>
      {children}
    </TaxContext.Provider>
  )
}
