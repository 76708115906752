import React from 'react'
import { useState } from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

/**
 * This is an example of layout animations in Framer Motion 2.
 *
 * It's as simple as adding a `layout` prop to the `motion.div`. When
 * the flexbox changes, the handle smoothly animates between layouts.
 *
 * Try adding whileHover={{ scale: 1.2 }} to the handle - the layout
 * animation is now fully compatible with user-set transforms.
 */

export default function Switch() {
  const [isOn, setIsOn] = useState(false)

  const toggleSwitch = () => setIsOn(!isOn)

  const spring = {
    type: 'spring',
    stiffness: 700,
    damping: 30,
  }

  return (
    <div
      className={classNames(
        'w-16 h-10   flex rounded-full p-1 cursor-pointer',
        isOn
          ? 'bg-white bg-opacity-40 justify-start'
          : 'justify-end bg-green-700',
      )}
      onClick={toggleSwitch}
    >
      <motion.div
        className="w-8 h-8 bg-white rounded-full"
        layout
        transition={spring}
      />
    </div>
  )
}
