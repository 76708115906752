import { useState } from 'react'
import { ethers, Contract, BigNumber, constants } from 'ethers'
import { Interface } from '@ethersproject/abi'
import format from 'date-fns/format'

import { NetworkIcon, ButtonPill } from '../../components'
import { CheckoutSession, ReciptInfo } from '../../types'
import { CheckIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { API_KEY } from '../../constants'
import { TicketIcon, GiftIcon } from '@heroicons/react/24/outline'
import {
  formatAddress,
  chainName,
  makeExplorerLink,
  ExplorerType,
} from '../../utils'

import { useAccount, useSigner, useNetwork } from 'wagmi'
import Recipt from './Recipt'

enum ReciptStatus {
  READY,
  MINTING,
  CREATED,
}

enum CouponStatus {
  READY,
  MINTING,
  CREATED,
  ERROR,
}

interface MintBasicPayload {
  tokenAddress: string
  to: string
  tokenURI: string
}

export function makeBasicCallData(payload: MintBasicPayload) {
  const NFT_RECIPT_INTERFACE = new Interface([
    'function mint(address to, string memory _tokenURI) public returns (uint256)',
  ])

  const callData = NFT_RECIPT_INTERFACE.encodeFunctionData('mint', [
    payload.to,
    payload.tokenURI,
  ])

  return {
    to: payload.tokenAddress,
    data: callData,
    value: constants.Zero,
  }
}

interface Props {
  checkoutSession: CheckoutSession
}

export default function Captured({ checkoutSession }: Props) {
  const { chain } = useNetwork()

  const [reciptStatus, setReciptStatus] = useState(ReciptStatus.READY)
  const [couponStatus, setCouponStatus] = useState(CouponStatus.READY)

  const [couponExplorer, setCouponExplorer] = useState('')

  const [recipt, setRecipt] = useState<ReciptInfo>()

  const { address, isConnected } = useAccount()
  const { data: signerWalletConnect, isError, isLoading } = useSigner()

  async function connectedWalletInfo() {
    try {
      if (isConnected) {
        if (!address) throw new Error('no wallet connect address')
        if (!chain?.id) throw new Error('no chain_id')
        if (!signerWalletConnect) throw new Error('no signerWalletConnect :(')

        return {
          address,
          chainId: `0x${chain?.id.toString(16)}`,
          signer: signerWalletConnect,
        }
      } else {
        if (!(window as any).ethereum) throw new Error('no window.ethereum')

        const provider = new ethers.providers.Web3Provider(
          (window as any).ethereum,
          'any',
        )

        if (!provider) {
          throw new Error('connect wallet webview - no provider')
        }
        const signer = provider.getSigner()
        if (!signer) throw new Error('no signer :(')

        const accounts = await (window as any).ethereum.request({
          method: 'eth_requestAccounts',
        })
        if (!accounts[0]) throw new Error('connect wallet webview - no account')

        const chainId = (await (window as any).ethereum.request({
          method: 'eth_chainId',
        })) as string

        if (!chainId) throw new Error('no chain_id')

        return {
          address: accounts[0],
          chainId,
          signer,
        }
      }
    } catch (err) {
      throw new Error('getting connected wallet info')
    }
  }

  async function mintCoupon() {
    setCouponStatus(CouponStatus.MINTING)

    try {
      const { address, chainId, signer } = await connectedWalletInfo()

      if (!address || !chainId) {
        console.warn('no chainid or address')
        return
      }

      const res = await fetch(
        `https://payments-api-c560.onrender.com/api/v1/coupon/TEST/mint`,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'X-API-KEY': API_KEY,
          },
          body: JSON.stringify({
            chain_id: BigNumber.from(chainId).toNumber(),
            receiver: address,
          }),
        },
      )
      const coupon = await res.json() // NOTE different type to Coupon FIXME..

      const nonce = await signer.getTransactionCount('pending')

      console.log(JSON.stringify(coupon, null, 4))

      const tx = await signer
        .getFeeData()
        .then(
          ({
            lastBaseFeePerGas,
            maxFeePerGas,
            maxPriorityFeePerGas,
            gasPrice,
          }) => {
            return signer.sendTransaction({
              nonce,
              type: 2, // EIP-1559 transaction type
              chainId: BigNumber.from(chainId).toNumber(),
              to: coupon.tx.to,
              data: coupon.tx.data,
              value: coupon.tx.value,
              // maxFeePerGas,
              // maxPriorityFeePerGas,
            })
          },
        )

      // setStatus(Status.LOADING)

      const txReceipt = await tx.wait()
      console.log(JSON.stringify(txReceipt, null, 4))

      const iface = new ethers.utils.Interface([
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: 'address',
              name: 'from',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'address',
              name: 'to',
              type: 'address',
            },
            {
              indexed: true,
              internalType: 'uint256',
              name: 'tokenId',
              type: 'uint256',
            },
          ],
          name: 'Transfer',
          type: 'event',
        },
      ])
      const transferEventTopic = iface.getEventTopic('Transfer')
      const transferLog = txReceipt.logs.find((log) =>
        log.topics.includes(transferEventTopic),
      )

      if (transferLog) {
        const events = iface.parseLog(transferLog)
        const tokenId = events.args.tokenId

        setCouponExplorer(
          makeExplorerLink({
            chainId: checkoutSession.transactions.approve_tx?.chainId,
            hash: coupon.tx.to,
            type: ExplorerType.Token,
            tokenId: tokenId.toString(),
          }),
        )
      } else {
        throw new Error('Transfer event log not found')
      }

      setCouponStatus(CouponStatus.CREATED)
    } catch (err) {
      if ((err as any).code !== 'ACTION_REJECTED') {
        alert(err)
      }
      console.error(err)
      setCouponStatus(CouponStatus.ERROR)
    }
  }

  async function mintRecipt() {
    setReciptStatus(ReciptStatus.MINTING)

    try {
      const { address, chainId, signer } = await connectedWalletInfo()

      if (!address || !chainId) {
        console.warn('no chainid or address')
        return
      }

      const res = await fetch(
        `https://payments-api-c560.onrender.com/api/v1/payment_requests/${checkoutSession?.id}/recipt`,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'X-API-KEY': API_KEY,
          },
        },
      )

      if (!res.ok) throw new Error('failed to fetch voucher')

      const recipt = await res.json()
      if (!recipt) throw new Error('no voucher :(')

      const txMint = await signer.sendTransaction(recipt.tx)

      const tx = await txMint.wait()

      console.log(JSON.stringify(tx, null, 4))

      setRecipt(recipt)
      setReciptStatus(ReciptStatus.CREATED)
    } catch (err) {
      if ((err as any).code === 'ACTION_REJECTED') {
        setReciptStatus(ReciptStatus.READY)
      } else {
        alert(err)
        setReciptStatus(ReciptStatus.READY) // FIXME ERROR ?
        console.error(err)
      }
    }
  }

  return (
    <div className="container mx-auto min-h-screen  flex flex-col justify-start p-4 md:p-10 fade-in">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-6">
          <section className="p-4">
            <div className="flex w-full flex-col justify-start mb-12 md:mb-24">
              <h1 className="text-white font-semibold text-xl">
                Thank you for shopping at
              </h1>
              <p>{checkoutSession.soft_descriptor}</p>
            </div>

            <div className="flex w-full flex-col justify-start mb-12 md:mb-24">
              <p className="text-white font-semibold">Order</p>
              <p className="mt-2">{checkoutSession.id}</p>
            </div>

            <div className="text-sm flex flex-col w-full mb-12 md:mb-24">
              <div className="flex w-full flex-row justify-start">
                <p className="text-white font-semibold">Details</p>
              </div>

              {checkoutSession?.display_items?.map((item) => (
                <div
                  key={item.key}
                  className="flex w-full flex-row justify-between mb-2"
                >
                  <p>{item.label}</p>

                  <p className="text-neutral-300">
                    {Number(item.value).toFixed(2)}
                  </p>
                </div>
              ))}

              <div className="flex w-full flex-row justify-between text-white">
                <p className="text-lg font-semibold">Total</p>

                <p className="text-lg font-semibold">
                  {checkoutSession?.total?.value}{' '}
                  {checkoutSession?.total?.currency}
                </p>
              </div>
            </div>

            <div className="flex w-full flex-row justify-between items-center mb-2">
              <div className="flex">
                <GiftIcon className="w-6 h-6 mr-2 text-greeeen" />
                <h3 className="text-lg font-semibold text-white">Recipt</h3>
              </div>
            </div>
            <div className="flex w-full flex-row justify-center items-center">
              {reciptStatus !== ReciptStatus.CREATED && (
                <ButtonPill onClick={mintRecipt}>
                  Mint Recipt
                  {reciptStatus === ReciptStatus.MINTING && (
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 ml-2 text-gray-200 animate-spin dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#1C64F2"
                      />
                    </svg>
                  )}
                </ButtonPill>
              )}

              {reciptStatus === ReciptStatus.CREATED && (
                <div className="flex flex-col mt-0">
                  <Recipt recipt={recipt} />
                </div>
              )}
            </div>
          </section>
        </div>

        <div className="col-span-0 md:col-span-2 px-4"></div>
        <div className="col-span-12 md:col-span-4 px-4">
          <section className="mb-12 md:mb-24">
            {couponStatus !== CouponStatus.CREATED ? (
              <>
                <div className="flex w-full flex-row justify-between items-center mb-2">
                  <div className="flex">
                    <TicketIcon className="w-6 h-6 mr-2 text-greeeen" />
                    <h3 className="text-lg font-semibold text-white">Coupon</h3>
                  </div>
                </div>

                <div className="flex w-full flex-col relative">
                  <div className="flex w-full flex-row justify-between items-center">
                    <img
                      alt="Voucher"
                      //  src="https://payments-webapp-assets-stage.s3.us-west-2.amazonaws.com/icon-20-off.png"
                      src="https://storage.googleapis.com/opensea-prod.appspot.com/puffs/9.png"
                      className="mr-2 rounded-md border border-slate-200 p-2 mb-5 shadow max-w-xs mb-5 bg-white w-28"
                    />
                    <div className="flex flex-col justify-between items-center">
                      <h1 className="text-neutral-100 mb-5">Here's 20% OFF.</h1>
                      <ButtonPill onClick={mintCoupon}>
                        Mint coupon
                        {couponStatus === CouponStatus.MINTING && (
                          <svg
                            aria-hidden="true"
                            role="status"
                            className="inline w-4 h-4 ml-2 text-gray-200 animate-spin dark:text-gray-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="#1C64F2"
                            />
                          </svg>
                        )}
                      </ButtonPill>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="flex w-full flex-row justify-between items-center">
                <p className="text-neutral-400 py-3">Coupon minted ✨</p>
                <a href={couponExplorer} target="_blank" rel="noreferrer">
                  <InformationCircleIcon className="w-4 h-4 text-neutral-300" />
                </a>
              </div>
            )}
          </section>

          <div className="text-sm flex flex-col w-full mb-0 mt-7">
            <div className="flex w-full flex-row justify-between mb-2">
              <p>Merchant ID</p>

              <p className="text-neutral-300">{checkoutSession.merchant_id}</p>
            </div>

            <div className="flex w-full flex-row justify-between mb-2">
              <p>Payment ID</p>

              <p className="text-neutral-300">{checkoutSession.id}</p>
            </div>

            <div className="flex w-full flex-row justify-between mb-4">
              <p>Completed on</p>

              <p className="text-neutral-300">
                {format(new Date(), 'h:m:ss b MM/dd/yyyy')}
              </p>
            </div>

            <div className="flex w-full flex-row justify-between mb-2">
              <p>Network</p>

              <div className="flex">
                <NetworkIcon
                  className="mr-2"
                  chainId={checkoutSession.transactions.approve_tx?.chainId}
                />
                <p className="text-neutral-300">
                  {chainName(checkoutSession.transactions.approve_tx?.chainId)}
                </p>
              </div>
            </div>

            <div className="flex w-full flex-row justify-between mb-2">
              <p>Approval:</p>

              <div className="flex">
                <a
                  href={makeExplorerLink({
                    chainId: checkoutSession.transactions.approve_tx?.chainId,
                    hash: checkoutSession.transactions?.approve_tx?.hash,
                    type: ExplorerType.Hash,
                  })}
                  target="_blank"
                  className="flex  justify-center  items-center text-neutral-300 underline"
                  rel="noreferrer"
                >
                  {formatAddress(
                    checkoutSession.transactions?.approve_tx?.hash ?? '',
                  )}
                  <InformationCircleIcon className="w-4 h-4 text-neutral-300 ml-2" />
                </a>
              </div>
            </div>

            <div className="flex w-full flex-row justify-between mb-2">
              <p>Transfer:</p>

              <div className="flex">
                <a
                  href={makeExplorerLink({
                    chainId:
                      checkoutSession.transactions.transfer_from_tx?.chainId,
                    hash: checkoutSession.transactions?.transfer_from_tx?.hash,
                    type: ExplorerType.Hash,
                  })}
                  target="_blank"
                  className="flex  justify-center  items-center text-neutral-300 underline"
                  rel="noreferrer"
                >
                  {formatAddress(
                    checkoutSession.transactions?.transfer_from_tx?.hash ?? '',
                  )}
                  <InformationCircleIcon className="w-4 h-4 text-neutral-300 ml-2" />
                </a>
              </div>
            </div>

            <div className="flex w-full flex-row justify-between mb-2">
              <p>Network fee</p>
              <p className="text-neutral-300">$0.01 </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
