import React, { useState } from 'react'

import { motion, Variants } from 'framer-motion'

interface ButtonProps {
  text: string
}

export default function ButtonAnimated({ text }: ButtonProps) {
  const [isClicked, setIsClicked] = useState(false)

  const handleClick = () => {
    setIsClicked(true)
    setTimeout(() => setIsClicked(false), 200)
  }

  const variants: Variants = {
    clicked: { scale: 1.2 },
    unclicked: { scale: 1 },
  }

  return (
    <motion.button
      onClick={handleClick}
      variants={variants}
      animate={isClicked ? 'clicked' : 'unclicked'}
    >
      {text}
    </motion.button>
  )
}
