import { useEffect, useState, useMemo, useCallback } from 'react'
import { motion, Variants } from 'framer-motion'

export default function ButtonBounce({
  children,
  onClick,
  className,
}: {
  children: React.ReactNode
  onClick: (e: any) => Promise<void> | ((e: any) => void) | void
  className?: string
}) {
  //const onClick = React.useCallback(() => onInput(input), [onInput, input])

  const [isClicked, setIsClicked] = useState(false)

  const handleClick = useCallback(
    (e: any) => {
      onClick(e)
      setIsClicked(true)
      setTimeout(() => setIsClicked(false), 150)
    },
    [onClick],
  )

  const variants: Variants = {
    clicked: { scale: 1.4 },
    unclicked: { scale: 1 },
  }

  return (
    <motion.button
      className={className}
      type="button"
      onClick={handleClick}
      variants={variants}
      animate={isClicked ? 'clicked' : 'unclicked'}
    >
      {children}
    </motion.button>
  )
}
