export interface FormattedTransaction {
  from: string
  to: string
  amount: string
  timestamp: string
  transactionHash: string
}

export interface Amount {
  value: string
  currency: string
}

export interface ReciptInfo {
  tx: Tx
  uri: string
}

export enum PaymentStatus {
  CREATED = 'CREATED',
  PAYER_ACTION_REQUIRED = 'PAYER_ACTION_REQUIRED',
  APPROVAL_PENDING = 'APPROVAL_PENDING',
  APPROVED = 'APPROVED',
  CAPTURE_PENDING = 'CAPTURE_PENDING',
  CAPTURED = 'CAPTURED',
  SETTLEMENT_PENDING = 'SETTLEMENT_PENDING',
  SETTLED = 'SETTLED',
  FAILED = 'FAILED',
}

export interface ChainInfo {
  chain_id: string
  rpc_urls: string[]
  chain_name: string
  native_currency: NativeCurrency
  block_explorer_urls: string[]
}

export interface NativeCurrency {
  name: string
  symbol: string
  decimals: number
}

export enum NativeCurrencyName {
  ETHER = 'ETH',
  MATIC = 'MATIC',
  BNB = 'BNB',
}

export interface TaxInfo {
  state: string
  zipCode: string
  taxRegionName: string
  estimated_combined_rate: number
  stateRate: number
  estimatedCountyRate: number
  estimatedCityRate: number
  estimatedSpecialRate: number
}

export enum ChainId {
  GOERLI = 5,
  SOLANA = 11111111111111,
  POLYGON = 137,
  POLYGON_MUMBAI = 80001,
  POLYGON_ZKEVM = 1101,
  POLYGON_ZKEVM_TESTNET = 1442,
  BASE_GOERLI = 84531,
  ARBITRUM = 42161,
  OPTIMISM = 10,
  OPTIMISM_GOERLI = 420,
}

export enum Network {
  POLYGON = 'polygon',
  POLYGON_MUMBAI = 'polygon-mumbai',
  POLYGON_ZKEVM = 'polygon-zkevm',
  POLYGON_ZKEVM_TESTNET = 'polygon-zkevm-testnet',
  BASE_GOERLI = 'base-goerli',
  ARBITRUM = 'arbitrum',
  OPTIMISM = 'optimism',
  OPTIMISM_GOERLI = 'optimism-goerli',
  GOERLI = 'goerli',
  SOLANA = 'solana',
}

export const chainIdHex: Record<Network, string> = {
  [Network.POLYGON]: `0x${ChainId.POLYGON.toString(16)}`,
  [Network.GOERLI]: `0x${ChainId.GOERLI.toString(16)}`,
  [Network.SOLANA]: `0x${ChainId.SOLANA.toString(16)}`,
  [Network.POLYGON_MUMBAI]: `0x${ChainId.POLYGON_MUMBAI.toString(16)}`,
  [Network.POLYGON_ZKEVM]: `0x${ChainId.POLYGON_ZKEVM.toString(16)}`,
  [Network.POLYGON_ZKEVM_TESTNET]: `0x${ChainId.POLYGON_ZKEVM_TESTNET.toString(
    16,
  )}`,
  [Network.BASE_GOERLI]: `0x${ChainId.BASE_GOERLI.toString(16)}`,
  [Network.ARBITRUM]: `0x${ChainId.ARBITRUM.toString(16)}`,
  [Network.OPTIMISM]: `0x${ChainId.OPTIMISM.toString(16)}`,
  [Network.OPTIMISM_GOERLI]: `0x${ChainId.OPTIMISM_GOERLI.toString(16)}`,
}

export const networkToChainId: Record<Network, ChainId> = {
  [Network.POLYGON]: ChainId.POLYGON,
  [Network.GOERLI]: ChainId.GOERLI,
  [Network.SOLANA]: ChainId.SOLANA,
  [Network.POLYGON_MUMBAI]: ChainId.POLYGON_MUMBAI,
  [Network.POLYGON_ZKEVM]: ChainId.POLYGON_ZKEVM,
  [Network.POLYGON_ZKEVM_TESTNET]: ChainId.POLYGON_ZKEVM_TESTNET,
  [Network.BASE_GOERLI]: ChainId.BASE_GOERLI,
  [Network.ARBITRUM]: ChainId.ARBITRUM,
  [Network.OPTIMISM]: ChainId.OPTIMISM,
  [Network.OPTIMISM_GOERLI]: ChainId.OPTIMISM_GOERLI,
}

export interface NativeBalance {
  value: number
  decimals: number
  symbol?: string
}

export interface WalletToken {
  symbol: string
  name: string
  balance: number
  decimals: number
}

export interface WalletInfo {
  chain_id: number
  address: string
  native_balance?: NativeBalance
  coupons: Coupon[]
  points?: Points
  tokens: WalletToken[]
}

export interface Points {
  value: number
  decimals: number
}
/*
 * Checkout Session
 */
export interface CheckoutSession {
  id: string
  merchant_id: string
  status: string
  total: Total
  display_items?: DisplayItem[]
  soft_descriptor: string
  networks: PaymentMethod[]
  transactions: Transactions
  wallet: WalletInfo
  rewards: Reward[]
}

export interface BurnTx {
  to: string
  data: string
  value: string
}

export interface Reward {
  id: string
  label: string
  points_required: number
  image: string
  burn_tx: BurnTx
}

export interface Total {
  value: string
  currency: string
}

export interface Coupon {
  name: string
  image: string
  bp: number
  uri: string
  contract_address: string
  token_id: number
  data: string
}

export enum DisplayItemType {
  ITEM = 'item',
  TAX = 'tax',
  GRATUITY = 'gratuity',
  SHIPPING = 'shipping',
}

export interface DisplayItem {
  key: string
  label: string
  type: DisplayItemType
  value: string
}

export interface Transactions {
  approve_tx: Tx
  transfer_from_tx: Tx
  coupon_burn_tx: null
}

export interface Tx {
  type: number
  chainId: number
  nonce: number
  to: string
  data: string
  hash: string
  from: string
  confirmations: number
}

export interface PaymentMethod {
  type: string
  chain: string
  display_name: string
  chain_id: number
  tokens: Token[]
  metadata: Metadatum[] | null
}

export interface Metadatum {
  identifier: string
  value: string
}

export interface Token {
  name: string
  symbol: string
  address: string
  decimals: number
  amount: number
  approve_data: string
  approval_target: string
  approval_tx: ApprovalTx
}

interface ApprovalTx {
  to: string
  data: string
  value: string
}

export enum Source {
  TERMINAL = 'terminal',
  APP = 'app',
}

export interface TipOption {
  key: string
  label: string
  value: number
}

/*
 *
 */

export interface PaymentRequest {
  id: string
  chain_id: number
  status: PaymentStatus
  created_at: string
  updated_at: string
  total: Total
  display_items: DisplayItem[]
  links: null
  transactions: Transactions
}

export interface GroupedData<T> {
  [key: string]: T[]
}
