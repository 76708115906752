import { DotAnimation } from '../../components'

export default function Loading() {
  return (
    <div className="flex flex-col justify-center items-center min-h-screen">
      <div className="flex flex-col justify-center items-center">
        <DotAnimation />
      </div>
    </div>
  )
}
