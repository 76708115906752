import { useEffect, useState, useReducer } from 'react'

import {
  EmailInput,
  NetworkSelectSwiper,
  NetworkSelect,
  WalletsEthereum,
  WalletsSolana,
  ShippingAddress,
} from '../../components'
import {
  PaymentStatus,
  CheckoutSession,
  Network,
  Source,
  DisplayItem,
  DisplayItemType,
} from '../../types'
import { useSalesTax } from '../../hooks'
import classNames from 'classnames'
import { CheckIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { useInterval, useScrollPosition } from '../../hooks'
import TipSelect from './TipSelect'

import * as featureFlag from '../../featureFlags'

import { TIP_REACT_KEY } from '../../constants'

import { useQuery } from '../../hooks'
import { isEmailValid } from '../../utils'
import { API_KEY } from '../../constants'
import { useDisconnect, useAccount } from 'wagmi'

const disableEmail = true

let initialState: State = {
  phone: '',
  email: '',
  zipCode: '',
}

try {
  const data = localStorage.getItem('STATE_V1')
  if (!data) throw new Error('no data')
  initialState = JSON.parse(data)
} catch (err) {
  console.error(err)
}

interface State {
  phone: string
  email: string
  zipCode: string
}

enum ActionType {
  EMAIL,
  PHONE,
  ZIPCODE,
}

type Action =
  | {
      type: ActionType.EMAIL
      email: string
    }
  | {
      type: ActionType.PHONE
      phone: string
    }
  | {
      type: ActionType.ZIPCODE
      zipCode: string
    }

function reducer(state: State, action: Action) {
  switch (action.type) {
    case ActionType.EMAIL:
      return {
        ...state,
        email: action.email,
      }
    case ActionType.PHONE:
      return {
        ...state,
        phone: action.phone,
      }
    case ActionType.ZIPCODE:
      return {
        ...state,
        zipCode: action.zipCode,
      }
    default:
      return state
  }
}

export default function Checkout() {
  const { disconnect } = useDisconnect()
  const [notFound, setNotFound] = useState(false)

  let query = useQuery()
  const id = query.get('id')
  const source = query.get('source') as Source

  const { address, isConnected } = useAccount()
  const { zipCode, updateZipCode, salesTaxRate } = useSalesTax()

  const [state, dispatch] = useReducer(reducer, initialState)

  const [tipDisplayItem, setTipDisplayItem] = useState<DisplayItem>()

  const scrollPositionY = useScrollPosition()

  const [showValidation, setShowValidation] = useState(false)

  const [selectedNetwork, setSelectedNetwork] = useState(Network.POLYGON)
  const [checkoutSession, setCheckoutSession] = useState<CheckoutSession>()

  const collectShippingAddress = false //source !== Source.TERMINAL

  const total =
    checkoutSession?.display_items
      ?.concat(
        tipDisplayItem
          ? tipDisplayItem
          : {
              key: TIP_REACT_KEY,
              value: '0',
              type: DisplayItemType.GRATUITY,
              label: 'TIP',
            },
      )
      .reduce((acc, curr) => acc + Number(curr.value), 0) ?? 0

  async function fetchPayment() {
    const res = await fetch(
      `https://payments-api-c560.onrender.com/api/v1/checkout_session/${id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (res.status === 404) {
      setNotFound(true)
      return
    }

    const checkoutSession = (await res.json()) as CheckoutSession

    if (!checkoutSession) throw new Error('no payment found..')

    setCheckoutSession(checkoutSession)
    setNotFound(false)
  }

  useEffect(() => {
    disconnect()
  }, [])

  useInterval(
    async () => {
      try {
        await fetchPayment()
      } catch (err) {
        console.error(err)
      }
    },
    // Delay in milliseconds or null to stop it
    checkoutSession?.status !== PaymentStatus.CAPTURED ? 2000 : null,
  )

  useEffect(() => {
    if (collectShippingAddress) {
      if (state.zipCode?.length === 5) {
        updateZipCode(state.zipCode)
      }
    }
  }, [collectShippingAddress, state.zipCode])

  useEffect(() => {
    if (id) {
      fetchPayment()
    }
  }, [id])

  async function updateStatus() {
    const res = await fetch(
      `https://payments-api-c560.onrender.com/api/v1/payment_requests/${id}`,
      {
        method: 'PATCH',
        headers: {
          accept: 'application/json',
          'X-API-KEY': API_KEY,
        },
        body: JSON.stringify([
          {
            op: 'replace',
            path: '/status',
            value: PaymentStatus.PAYER_ACTION_REQUIRED,
          },
        ]),
      },
    )
    if (!res.ok) throw new Error('failed to fetch checkout session')
  }

  useEffect(() => {
    updateStatus()
  }, [])

  function isValid() {
    // HACK FIXME
    if (source === Source.TERMINAL) return true

    setShowValidation(true)

    const isValid = isEmailValid(state.email)

    if (!isValid) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }

    localStorage.setItem('STATE_V1', JSON.stringify(state))
    return isValid
  }
  /*
   * TODO - Error page
   */
  if (!id) {
    return <div>oops misssing id param</div>
  }

  if (notFound) {
    return (
      <main className="max-w-sm min-h-screen mx-auto flex flex-col justify-start md:justify-center items-center px-10 pl-10 py-4">
        <div className="flex flex-col justify-center items-center">
          <svg
            className="w-12 h-12 mb-5 text-neutral-800"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>

          <h3>Payment expired</h3>
        </div>
      </main>
    )
  }

  if (checkoutSession?.status === PaymentStatus.APPROVAL_PENDING) {
    return (
      <div className="max-w-sm min-h-screen mx-auto flex flex-col justify-center p-10">
        <div className="max-w-sm flex flex-col justify-center text-center">
          <p>PENDING Approval</p>
          <p>
            {/*formatAddress(checkoutSession?.transactionHash ?? '')*/
            /*FIXME */}
          </p>
        </div>
      </div>
    )
  }

  if (checkoutSession?.status === PaymentStatus.APPROVED) {
    return (
      <div className="flex flex-col justify-center items-center min-h-screen">
        <div className="flex flex-col justify-center items-center">
          <CheckIcon className="h-16 w-16 mb-7 text-neutral-300" />
          <p>Approved</p>
          <p>{/*formatAddress(checkoutSession?.transactionHash ?? '')*/}</p>
        </div>
      </div>
    )
  }

  if (checkoutSession?.status === PaymentStatus.CAPTURED) {
    return (
      <div className="max-w-sm min-h-screen mx-auto flex flex-col justify-center p-10">
        <div className="max-w-sm flex flex-col items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-16 h-16 mb-8 text-neutral-800"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
            />
          </svg>

          <p className="text-3xl">Thank you!</p>
        </div>
      </div>
    )
  }

  return (
    <div className="scrollbar-hide">
      <div
        className={classNames(
          'md:max-w-2xl',
          'md:mx-auto',
          'bg-black',
          scrollPositionY > 0 ? 'shadow' : 'shadow-none',
          'sticky top-0 z-20  transition-shadow',
          'flex w-full items-center mt-1 mb-3 pt-5 pb-5 px-6',
        )}
      >
        {/*<pre>
          {JSON.stringify({ salesTaxAmount, zipCode, salesTaxRate }, null, 4)}
        </pre>*/}
        <div className="w-full flex justify-between items-center">
          <div className="flex justify-center items-center">
            Pay{' '}
            {checkoutSession && (
              <p className="text-xl ml-2 font-medium text-gray-700 dark:text-white">
                {checkoutSession?.soft_descriptor}
              </p>
            )}
            {!checkoutSession && (
              <div
                style={{
                  width: '80px',
                  height: '25px',
                }}
                className="shimmer-wrapper shimmer-animate ml-3 mb-2"
              ></div>
            )}
          </div>

          {scrollPositionY > 86 && (
            <p className="fade-in font-semibold text-2xl">
              {total.toFixed(2)} USDC
            </p>
          )}
        </div>
      </div>

      {!featureFlag.disableTip && source === Source.TERMINAL && (
        <div className="px-6 mb-5 md:max-w-2xl md:mx-auto">
          <TipSelect
            updateTipDisplayItem={(DisplayItem) =>
              setTipDisplayItem(DisplayItem)
            }
            checkoutSession={checkoutSession}
            tips={[
              {
                key: '1',
                label: '15%',
                value: 0.15,
              },
              {
                key: '2',
                label: '20%',
                value: 0.2,
              },
              {
                key: '3',
                label: '25%',
                value: 0.25,
              },
            ]}
          />
        </div>
      )}

      {/* 
        Checkout 
       */}
      {(featureFlag.disableTip || source !== Source.TERMINAL) && (
        <div className="px-6 md:max-w-2xl mb-5 md:mx-auto">
          <h3 className="mb-3 mt-3">Summary</h3>
          {checkoutSession?.display_items?.map((item) => {
            if (item.label.toLowerCase().includes('sales tax')) {
              return (
                <div
                  key={`${item.label}:${item.value}`}
                  className="flex justify-between text-neutral-400 fade-in"
                >
                  <p>
                    {item.label}{' '}
                    <span className="text-xs ml-5">
                      {state?.zipCode?.length === 5 &&
                        `${(salesTaxRate * 100).toFixed(2)} %`}
                    </span>
                  </p>

                  <p>{Number(item.value).toFixed(2)}</p>
                </div>
              )
            } else {
              return (
                <div
                  key={`${item.label}:${item.value}`}
                  className="flex justify-between text-neutral-400 fade-in"
                >
                  <p>{item.label}</p>
                  <p>{Number(item.value).toFixed(2)} </p>
                </div>
              )
            }
          })}
          <div className="flex text-lg text-white  semibold justify-between">
            <p>Total</p>
            <p>
              {checkoutSession?.total?.value} {checkoutSession?.total?.currency}
            </p>
          </div>
        </div>
      )}

      <div className="px-6 md:max-w-2xl md:mx-auto">
        {!disableEmail && (
          <>
            {source === Source.TERMINAL ? (
              <h3 className="mb-3">Recipt</h3>
            ) : (
              <h3 className="mb-3">Contact</h3>
            )}
            <EmailInput
              value={state.email}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                const email = (e.target as HTMLInputElement).value
                setShowValidation(false)
                dispatch({ type: ActionType.EMAIL, email })
              }}
              onFocus={() => setShowValidation(false)}
              showValidation={showValidation && !isEmailValid(state.email)}
            />
          </>
        )}
        {collectShippingAddress && (
          <>
            <h3 className="mb-5 mt-10 font-semibold">Shipping</h3>
            <ShippingAddress
              zipValue={state.zipCode}
              onZipChange={(e: React.FormEvent<HTMLInputElement>) => {
                const zipCode = (e.target as HTMLInputElement).value
                if (zipCode.length > 5) return

                updateZipCode(zipCode)
                setShowValidation(false)
                dispatch({ type: ActionType.ZIPCODE, zipCode })
              }}
            />
          </>
        )}
        <h3 className="mb-1 mt-10">Select a network</h3>
        <p className="text-sm text-neutral-400 mb-3 flex items-center">
          Network fees apply <InformationCircleIcon className="w-4 h-4 ml-2" />{' '}
        </p>
        <NetworkSelect
          setNetwork={setSelectedNetwork}
          network={selectedNetwork}
          checkoutSession={checkoutSession}
        />
        <br />
        <h3 className="mb-1 mt-3">Select a wallet</h3>
        {selectedNetwork !== Network.SOLANA && (
          <WalletsEthereum
            isValid={isValid}
            checkoutSession={checkoutSession}
            network={selectedNetwork}
          />
        )}
        {selectedNetwork === Network.SOLANA && (
          <WalletsSolana isValid={isValid} checkoutSession={checkoutSession} />
        )}
        {isConnected && (
          <button onClick={() => disconnect()}>Disconnect</button>
        )}
      </div>
    </div>
  )
}
