import { AnimatePresence, motion } from 'framer-motion'

interface Props {
  isVisible: boolean
  children: React.ReactNode
}

export default function FadeOut({ isVisible, children }: Props) {
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
