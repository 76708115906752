interface Props {
  networkName?: string
  symbol?: string
}

export default function NativeBalanceWarning({ networkName, symbol }: Props) {
  return (
    <div className="p-4 bg-neutral-900 rounded-lg">
      <p className="flex">
        ℹ️ You don't have any native {networkName} {symbol}
      </p>
    </div>
  )
}
