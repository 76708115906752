import {
  CheckIcon,
  XMarkIcon,
  ViewfinderCircleIcon,
} from '@heroicons/react/20/solid'

import { NameDisplay } from '../../components'

interface Props {
  onClick: () => void
  zipCode?: string
  salesTaxRate: number
}

export default function MobileMenu({ onClick, zipCode, salesTaxRate }: Props) {
  return (
    <div>
      <button onClick={onClick} className="fixed right-5 top-5">
        <XMarkIcon className="h-8 w-8 text-gray-300" />
      </button>
      <div className="flex flex-col container mx-auto px-5 mt-16">
        <h3 className="mb-5">Settings ⚙️</h3>
        <p className="mb-5">Zipcode: {zipCode}</p>
        <p className="mb-5">
          Sales tax rate: {(salesTaxRate * 100).toFixed(2)} %
        </p>
      </div>
    </div>
  )
}
