import { parseISO, format } from 'date-fns'
import { GroupedData } from '../types'

export function walletDisplayName(wallet: string | undefined | null) {
  switch (wallet) {
    case 'metamask':
      return 'MetaMask Wallet'
    case 'coinbase':
      return 'Coinbase Wallet'
    default:
      return ''
  }
}

export function formatAddress(address: string = '') {
  return (
    address.slice(0, 4) +
    '...' +
    address.slice(address.length - 4, address.length)
  )
}

export function formatNetworkName(str: string) {
  return str
    .split('-') // split string at dashes
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize first letter of each word
    .join(' ') // join words with a space
}

interface DataItem {
  updated_at: string
}

export function groupDataByDay<T extends DataItem>(data: T[]): GroupedData<T> {
  const groupedData: GroupedData<T> = {}

  data.forEach((item) => {
    const updatedAt = parseISO(item.updated_at)
    const day = format(updatedAt, 'yyyy-MM-dd')

    if (groupedData[day]) {
      groupedData[day].push(item)
    } else {
      groupedData[day] = [item]
    }
  })

  return groupedData
}
