import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createClient, WagmiConfig } from 'wagmi'

import { ErrorBoundary } from './components'
import {
  arbitrum,
  mainnet,
  polygon,
  goerli,
  baseGoerli,
  optimismGoerli,
  polygonZkEvm,
  polygonMumbai,
} from 'wagmi/chains'

import {
  Home,
  Scan,
  Checkout,
  Pay,
  Design,
  ERC20Permit,
  Sign,
  Activity,
  ActivityDetail,
  Loading,
  Settings,
  Mint,
  TransferRequest,
} from './screens'

const chains = [
  goerli,
  arbitrum,
  mainnet,
  polygon,
  baseGoerli,
  optimismGoerli,
  polygonMumbai,
]
const projectId = 'ea38fc35fd99ea9642032829f6ea12f9'

const { provider } = configureChains(chains, [w3mProvider({ projectId })])

const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider,
})

const ethereumClient = new EthereumClient(wagmiClient, chains)

function App() {
  return (
    <div className="dark bg-black text-white">
      <ErrorBoundary>
        <WagmiConfig client={wagmiClient}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/activity" element={<Activity />} />
            <Route
              path="/activity/:paymentRequestId"
              element={<ActivityDetail />}
            />
            <Route path="/loading" element={<Loading />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/mint" element={<Mint />} />

            <Route path="/scan" element={<Scan />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/pay" element={<Pay />} />
            <Route path="/design" element={<Design />} />
            <Route path="/permit" element={<ERC20Permit />} />
            <Route path="/sign" element={<Sign />} />
            <Route path="/transfer-request" element={<TransferRequest />} />
          </Routes>
          <Web3Modal
            themeVariables={{
              '--w3m-font-family': 'Roboto, sans-serif',
              // '--w3m-accent-color': '#FFFFFF',
              '--w3m-background-color': '#222',
            }}
            projectId={projectId}
            ethereumClient={ethereumClient}
          />
        </WagmiConfig>
      </ErrorBoundary>
    </div>
  )
}

export default App
